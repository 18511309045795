import { Component, OnInit } from '@angular/core';
import { DataService, ToolkitDto, SaveItemViewRequest, ItemViewLocation } from '../../models/generated';
import { ItemViewService } from '../../services/itemView.service';

@Component({
  selector: 'app-toolkit',
  templateUrl: './toolkit.component.html',
  styleUrls: ['./toolkit.component.scss'],
})
export class ToolkitComponent implements OnInit {
  toolkits: ToolkitDto[] = [];
  loading = true;
  constructor(private dataService: DataService, private itemViewService: ItemViewService) {}

  ngOnInit() {
    this.getToolkits();
  }

  async getToolkits() {
    await this.dataService.toolkit.getToolkits().subscribe(result => {
      this.toolkits = result;
      this.loading = false;
    });
  }
  linkClicked(id: number) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ LinkId: id, Location: ItemViewLocation.Toolkit }));
  }
}
