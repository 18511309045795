
export class ItemViewLocation {
	public static readonly AcquisitionNavigator: string = 'AcquisitionNavigator';
	public static readonly BookmarkedQuickLink: string = 'BookmarkedQuickLink';
	public static readonly Bookmarks: string = 'Bookmarks';
	public static readonly EditDocument: string = 'EditDocument';
	public static readonly MostVisited: string = 'MostVisited';
	public static readonly MyUploads: string = 'MyUploads';
	public static readonly NewUploads: string = 'NewUploads';
	public static readonly RecentlyViewed: string = 'RecentlyViewed';
	public static readonly Search: string = 'Search';
	public static readonly Toolkit: string = 'Toolkit';


	public constructor(
		fields?: Partial<ItemViewLocation>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
