
export class DocToLifecycleTestDto {
	public DocumentId: number ;
	public Id: number ;
	public LifecycleId: number ;

	public constructor(
		fields?: Partial<DocToLifecycleTestDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
