import { UserAccessLevelDto } from './UserAccessLevelDto';
import { UserPermissionDto } from './UserPermissionDto';

export class UserDto {
	public AccessLevels: UserAccessLevelDto[]  = [];
	public Email: string ;
	public Enabled: boolean ;
	public FirstName: string ;
	public Id: number ;
	public LastName: string ;
	public Permissions: UserPermissionDto[]  = [];
	public UserName: string ;

	public constructor(
		fields?: Partial<UserDto>) {

		if (fields) {


			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new UserAccessLevelDto(x)); }
			if (fields.Permissions) { fields.Permissions = fields.Permissions.map(x => new UserPermissionDto(x)); }
			Object.assign(this, fields);
		}
	}
}
