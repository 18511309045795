import { DocumentToAccessLevelDto } from './DocumentToAccessLevelDto';
import { DocumentToLifecycleDto } from './DocumentToLifecycleDto';
import { DocumentToRoleDto } from './DocumentToRoleDto';
import { DocumentToTagDto } from './DocumentToTagDto';

export class UpdateDocumentObjectRequest {
	public AccessLevels: DocumentToAccessLevelDto[]  = [];
	public Description: string ;
	public Id: number ;
	public Lifecycles: DocumentToLifecycleDto[]  = [];
	public Roles: DocumentToRoleDto[]  = [];
	public Tags: DocumentToTagDto[]  = [];
	public Title: string ;

	public constructor(
		fields?: Partial<UpdateDocumentObjectRequest>) {

		if (fields) {


			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new DocumentToAccessLevelDto(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new DocumentToLifecycleDto(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new DocumentToRoleDto(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new DocumentToTagDto(x)); }
			Object.assign(this, fields);
		}
	}
}
