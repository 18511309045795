import * as moment from 'moment';

export class DocumentVersionTestDto {
	public CreatedDate: moment.Moment ;
	public DocumentId: number ;
	public DocumentName: string ;
	public DocumentStatusTypeCode: string ;
	public FilePath: string ;
	public Id: number ;
	public PreviewPath: string ;

	public constructor(
		fields?: Partial<DocumentVersionTestDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }

			Object.assign(this, fields);
		}
	}
}
