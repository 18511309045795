import { LifeCycleItemDto } from './LifeCycleItemDto';

export class LifecycleGroupsDto {
	public Id: number ;
	public LifeCycles: LifeCycleItemDto[]  = [];
	public Name: string ;
	public Ordinal: number ;
	public SvgFileName: string ;
	public SvgFilePath: string ;
	public SvgForLifecycle: boolean ;

	public constructor(
		fields?: Partial<LifecycleGroupsDto>) {

		if (fields) {


			if (fields.LifeCycles) { fields.LifeCycles = fields.LifeCycles.map(x => new LifeCycleItemDto(x)); }
			Object.assign(this, fields);
		}
	}
}
