import { Link } from './Link';
import { Tag } from './Tag';

export class LinkToTag {
	public Id: number ;
	public Link: Link ;
	public LinkId: number ;
	public Tag: Tag ;
	public TagId: number ;

	public constructor(
		fields?: Partial<LinkToTag>) {

		if (fields) {
			if (fields.Link) { fields.Link = new Link(fields.Link); }
			if (fields.Tag) { fields.Tag = new Tag(fields.Tag); }


			Object.assign(this, fields);
		}
	}
}
