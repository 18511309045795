import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService, SaveUserBookmarkRequest, QuickLink } from '../models/generated';
import { ItemViewService } from './itemView.service';

@Injectable()
export class BookmarksService {
  public bookmarks: QuickLink[] = [];
  constructor(private dataService: DataService, private itemViewService: ItemViewService) {
    this.refresh();
  }

  async refresh() {
    this.bookmarks = await this.dataService.userBookmark.getMyBookmarks().toPromise();
  }

  async saveUserBookmark(docId: number, linkId: number, navigatorId: number) {
    await this.dataService.userBookmark
      .save(new SaveUserBookmarkRequest({ DocumentId: docId, LinkId: linkId, NavigatorId: navigatorId }))
      .toPromise();
    this.refresh();
    this.itemViewService.refresh();
  }

  isBookmarked(id: number, type: string): Boolean {
    return this.bookmarks.findIndex(b => b.Id === id && b.ItemType === type) > -1;
  }
}
