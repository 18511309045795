
export class GetLibraryTagsAdminRequest {
	public CountLifecycle: number ;
	public CountRole: number ;
	public CountTag: number ;
	public LifecycleTerm: string ;
	public RoleTerm: string ;
	public StartLifecycle: number ;
	public StartRole: number ;
	public StartTag: number ;
	public Status: string ;
	public TagTerm: string ;

	public constructor(
		fields?: Partial<GetLibraryTagsAdminRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
