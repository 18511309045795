
export class GetUsersRequest {
	public Count: number ;
	public Start: number ;
	public Status: string ;
	public Term: string ;

	public constructor(
		fields?: Partial<GetUsersRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
