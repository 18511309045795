import { SearchResult } from './SearchResult';

export class SearchResultList {
	public Results: SearchResult[]  = [];
	public Total: number ;

	public constructor(
		fields?: Partial<SearchResultList>) {

		if (fields) {


			if (fields.Results) { fields.Results = fields.Results.map(x => new SearchResult(x)); }
			Object.assign(this, fields);
		}
	}
}
