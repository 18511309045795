import { Document } from './Document';
import { Lifecycle } from './Lifecycle';

export class DocumentToLifecycle {
	public Document: Document ;
	public DocumentId: number ;
	public Id: number ;
	public Lifecycle: Lifecycle ;
	public LifecycleId: number ;

	public constructor(
		fields?: Partial<DocumentToLifecycle>) {

		if (fields) {
			if (fields.Document) { fields.Document = new Document(fields.Document); }
			if (fields.Lifecycle) { fields.Lifecycle = new Lifecycle(fields.Lifecycle); }


			Object.assign(this, fields);
		}
	}
}
