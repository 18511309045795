
export class SaveAccessLevelRequest {
	public Description: string ;
	public Enabled: boolean ;
	public Id: number ;
	public Name: string ;

	public constructor(
		fields?: Partial<SaveAccessLevelRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
