import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CurrentUserDto, DataService, SaveLinkRequest, UpdateDocumentObjectRequest } from '../models/generated';
import { AddLinkComponent } from '../home/add-link/add-link.component';
import { DocumentUploadComponent } from '../home/document-upload/document-upload.component';
import { MyUploadsService } from '../services/my-uploads.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-add-content-button',
  templateUrl: './add-content-button.component.html',
  styleUrls: ['./add-content-button.component.scss'],
})
export class AddContentButtonComponent implements OnInit {
  currentUser: CurrentUserDto = new CurrentUserDto();
  showAddContentList = false;

  constructor(public matDialog: MatDialog, private myUploadsService: MyUploadsService, private userService: UserService) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
  }

  async ngOnInit() {
    this.setCurrentUser(this.userService.cu);
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  showContentList() {
    return (this.showAddContentList = !this.showAddContentList);
  }

  async openLinkModal() {
    const link = new SaveLinkRequest();
    await AddLinkComponent.open(this.matDialog, link);
    this.myUploadsService.refresh(this.currentUser);
    this.showContentList();
  }

  async openDocumentModal() {
    const document = new UpdateDocumentObjectRequest();
    await DocumentUploadComponent.open(this.matDialog, document);
    this.myUploadsService.refresh(this.currentUser);
    this.showContentList();
  }
}
