// tslint:disable:max-line-length
// tslint:disable:member-ordering
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { AccessLevelDto } from '.';
import { AdminLibraryTags } from '.';
import { ApplicationValue } from '.';
import { ApprovalItemDto } from '.';
import { ApprovedLinkDto } from '.';
import { BookmarkedDocumentDto } from '.';
import { BookmarkedLinkDto } from '.';
import { CurrentUserDto } from '.';
import { DocumentInfoDto } from '.';
import { DocumentRequestDto } from '.';
import { DocumentTestDto } from '.';
import { EditDocumentDto } from '.';
import { EditLinkDto } from '.';
import { GetLibraryTagsAdminRequest } from '.';
import { GetUsersRequest } from '.';
import { HomepageDocumentResultList } from '.';
import { HomepageSectionsDto } from '.';
import { ItemType } from '.';
import { ItemViewLocation } from '.';
import { LibraryTags } from '.';
import { LibraryTagsDto } from '.';
import { LifecycleGroupsDto } from '.';
import { LifecycleGroupTestDto } from '.';
import { LinkRequestDto } from '.';
import { MyUploadsResultList } from '.';
import { NewsDto } from '.';
import { PermissionDto } from '.';
import { QuickLink } from '.';
import { QuickLinksDto } from '.';
import { ReplaceDocumentVersionAndDeleteRequest } from '.';
import { RestoreDocumentVersionRequest } from '.';
import { SaveAccessLevelRequest } from '.';
import { SaveHomepageSectionRequest } from '.';
import { SaveItemViewRequest } from '.';
import { SaveLifeCycleGroupRequest } from '.';
import { SaveLifecycleRequest } from '.';
import { SaveLinkRequest } from '.';
import { SaveNewsRequest } from '.';
import { SaveRoleRequest } from '.';
import { SaveTagRequest } from '.';
import { SaveToolkitRequest } from '.';
import { SaveUserBookmarkRequest } from '.';
import { SaveUserRequest } from '.';
import { SaveWhitelistExtensionRequest } from '.';
import { SearchRequest } from '.';
import { SearchResultList } from '.';
import { ToolkitDto } from '.';
import { UpdateApprovalItemRequest } from '.';
import { UpdateDeleteDocumentRequest } from '.';
import { UpdateDeleteLinkRequest } from '.';
import { UpdateDocumentObjectRequest } from '.';
import { UpdateUserEmailRequest } from '.';
import { UserListDto } from '.';
import { UserTestDto } from '.';
import { WhitelistTag } from '.';

@Injectable()
export class DataService {
	constructor(private http: HttpClient) {}

	apiRelativePath: string = 'api';

	applicationValue = {
		getAllValues: (): Observable<ApplicationValue[]> => this.http.get<ApplicationValue[]>(`${this.apiRelativePath}/ApplicationValue/GetAllValues`).pipe(catchError(this.handleError)),
		setValues: (values: ApplicationValue[]): Observable<ApplicationValue[]> => this.http.post<ApplicationValue[]>(`${this.apiRelativePath}/ApplicationValue/SetValues`, values).pipe(catchError(this.handleError)),
	};
	accessLevel = {
		getAccessLevels: (): Observable<AccessLevelDto[]> => this.http.get<AccessLevelDto[]>(`${this.apiRelativePath}/AccessLevel/GetAccessLevels`).pipe(catchError(this.handleError)),
		updateAccessLevel: (request: SaveAccessLevelRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/AccessLevel/UpdateAccessLevel`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	document = {
		download: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Document/Download?id=${id}`).pipe(catchError(this.handleError)),
		downloadPreview: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Document/DownloadPreview?id=${id}`).pipe(catchError(this.handleError)),
		downloadVersion: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Document/DownloadVersion?id=${id}`).pipe(catchError(this.handleError)),
		downloadVersionPreview: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Document/DownloadVersionPreview?id=${id}`).pipe(catchError(this.handleError)),
		getDocumentInfo: (id: number): Observable<DocumentInfoDto> => this.http.get<DocumentInfoDto>(`${this.apiRelativePath}/Document/GetDocumentInfo?id=${id}`).pipe(catchError(this.handleError)),
		getEditDocument: (id: number): Observable<EditDocumentDto> => this.http.get<EditDocumentDto>(`${this.apiRelativePath}/Document/GetEditDocument?id=${id}`).pipe(catchError(this.handleError)),
		replaceDocumentVersionAndDelete: (request: ReplaceDocumentVersionAndDeleteRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Document/ReplaceDocumentVersionAndDelete`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		restoreDocumentVersion: (request: RestoreDocumentVersionRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Document/RestoreDocumentVersion`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		update: (request: UpdateDocumentObjectRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Document/Update`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		updateDeleteDocument: (request: UpdateDeleteDocumentRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Document/UpdateDeleteDocument`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		upload: (): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Document/Upload`, null).pipe(catchError(this.handleError)),
	};
	homepageSection = {
		deleteHomepageSection: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/HomepageSection/DeleteHomepageSection`, id).pipe(catchError(this.handleError)),
		downloadVersion: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/HomepageSection/DownloadVersion?id=${id}`).pipe(catchError(this.handleError)),
		getDocumentsForHomepageSections: (homepageSectionId: number): Observable<HomepageDocumentResultList> => this.http.get<HomepageDocumentResultList>(`${this.apiRelativePath}/HomepageSection/GetDocumentsForHomepageSections?homepageSectionId=${homepageSectionId}`).pipe(catchError(this.handleError)),
		getHomepageSections: (): Observable<HomepageSectionsDto[]> => this.http.get<HomepageSectionsDto[]>(`${this.apiRelativePath}/HomepageSection/GetHomepageSections`).pipe(catchError(this.handleError)),
		saveHomepageSection: (request: SaveHomepageSectionRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/HomepageSection/SaveHomepageSection`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	libraries = {
		deleteLifecycle: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Libraries/DeleteLifecycle`, id).pipe(catchError(this.handleError)),
		deleteRole: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Libraries/DeleteRole`, id).pipe(catchError(this.handleError)),
		deleteTag: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Libraries/DeleteTag`, id).pipe(catchError(this.handleError)),
		getLibraryTags: (): Observable<LibraryTags> => this.http.get<LibraryTags>(`${this.apiRelativePath}/Libraries/GetLibraryTags`).pipe(catchError(this.handleError)),
		getLibraryTagsAdmin: (request: GetLibraryTagsAdminRequest): Observable<AdminLibraryTags> => this.http.post<AdminLibraryTags>(`${this.apiRelativePath}/Libraries/GetLibraryTagsAdmin`, request).pipe(catchError(this.handleError)),
		saveLifecycle: (request: SaveLifecycleRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Libraries/SaveLifecycle`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		saveRole: (request: SaveRoleRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Libraries/SaveRole`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		saveTag: (request: SaveTagRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Libraries/SaveTag`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	lifeCycleGroup = {
		deleteLifecycleGroup: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/LifeCycleGroup/DeleteLifecycleGroup`, id).pipe(catchError(this.handleError)),
		getImagePreview: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/LifeCycleGroup/GetImagePreview?id=${id}`).pipe(catchError(this.handleError)),
		getLifeCycleGroups: (): Observable<LifecycleGroupsDto[]> => this.http.get<LifecycleGroupsDto[]>(`${this.apiRelativePath}/LifeCycleGroup/GetLifeCycleGroups`).pipe(catchError(this.handleError)),
		saveGroup: (request: SaveLifeCycleGroupRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/LifeCycleGroup/SaveGroup`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		uploadLifecycleSvg: (): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/LifeCycleGroup/UploadLifecycleSvg`, null).pipe(catchError(this.handleError)),
	};
	news = {
		deleteNews: (Id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/News/DeleteNews`, Id).pipe(catchError(this.handleError)),
		getNews: (): Observable<NewsDto[]> => this.http.get<NewsDto[]>(`${this.apiRelativePath}/News/GetNews`).pipe(catchError(this.handleError)),
		getNewsImagePreview: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/News/GetNewsImagePreview?id=${id}`).pipe(catchError(this.handleError)),
		saveNews: (request: SaveNewsRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/News/SaveNews`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		uploadNewsImage: (): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/News/UploadNewsImage`, null).pipe(catchError(this.handleError)),
	};
	quickLinks = {
		getQuickLinks: (): Observable<QuickLinksDto> => this.http.get<QuickLinksDto>(`${this.apiRelativePath}/QuickLinks/GetQuickLinks`).pipe(catchError(this.handleError)),
		saveItemView: (request: SaveItemViewRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/QuickLinks/SaveItemView`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	approvals = {
		getItemsForApproval: (): Observable<ApprovalItemDto[]> => this.http.get<ApprovalItemDto[]>(`${this.apiRelativePath}/Approvals/GetItemsForApproval`).pipe(catchError(this.handleError)),
		updateApprovalItem: (request: UpdateApprovalItemRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Approvals/UpdateApprovalItem`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	search = {
		getItemType: (): Observable<ItemType> => this.http.get<ItemType>(`${this.apiRelativePath}/Search/GetItemType`).pipe(catchError(this.handleError)),
		getItemViewLocation: (): Observable<ItemViewLocation> => this.http.get<ItemViewLocation>(`${this.apiRelativePath}/Search/GetItemViewLocation`).pipe(catchError(this.handleError)),
		search: (request: SearchRequest): Observable<SearchResultList> => this.http.post<SearchResultList>(`${this.apiRelativePath}/Search/Search`, request).pipe(catchError(this.handleError)),
	};
	link = {
		getEditLink: (id: number): Observable<EditLinkDto> => this.http.get<EditLinkDto>(`${this.apiRelativePath}/Link/GetEditLink?id=${id}`).pipe(catchError(this.handleError)),
		saveLink: (request: SaveLinkRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Link/SaveLink`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		updateDeleteLink: (request: UpdateDeleteLinkRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Link/UpdateDeleteLink`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	settings = {
		deleteWhitelistExtension: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Settings/DeleteWhitelistExtension`, id).pipe(catchError(this.handleError)),
		getWhitelistTags: (): Observable<WhitelistTag[]> => this.http.get<WhitelistTag[]>(`${this.apiRelativePath}/Settings/GetWhitelistTags`).pipe(catchError(this.handleError)),
		saveWhitelistExtension: (request: SaveWhitelistExtensionRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Settings/SaveWhitelistExtension`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	test = {
		getApprovedDocument: (): Observable<DocumentRequestDto> => this.http.get<DocumentRequestDto>(`${this.apiRelativePath}/Test/GetApprovedDocument`).pipe(catchError(this.handleError)),
		getApprovedDocuments: (): Observable<DocumentTestDto> => this.http.get<DocumentTestDto>(`${this.apiRelativePath}/Test/GetApprovedDocuments`).pipe(catchError(this.handleError)),
		getApprovedLink: (): Observable<LinkRequestDto> => this.http.get<LinkRequestDto>(`${this.apiRelativePath}/Test/GetApprovedLink`).pipe(catchError(this.handleError)),
		getAuditCheckpoint: (): Observable<number> => this.http.get(`${this.apiRelativePath}/Test/GetAuditCheckpoint`, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		getBookmarkedDocument: (): Observable<BookmarkedDocumentDto> => this.http.get<BookmarkedDocumentDto>(`${this.apiRelativePath}/Test/GetBookmarkedDocument`).pipe(catchError(this.handleError)),
		getBookmarkedLink: (): Observable<BookmarkedLinkDto> => this.http.get<BookmarkedLinkDto>(`${this.apiRelativePath}/Test/GetBookmarkedLink`).pipe(catchError(this.handleError)),
		getLibraryTags: (): Observable<LibraryTagsDto> => this.http.get<LibraryTagsDto>(`${this.apiRelativePath}/Test/GetLibraryTags`).pipe(catchError(this.handleError)),
		getLifecycleGroups: (): Observable<LifecycleGroupTestDto> => this.http.get<LifecycleGroupTestDto>(`${this.apiRelativePath}/Test/GetLifecycleGroups`).pipe(catchError(this.handleError)),
		getPendingDocument: (): Observable<DocumentRequestDto> => this.http.get<DocumentRequestDto>(`${this.apiRelativePath}/Test/GetPendingDocument`).pipe(catchError(this.handleError)),
		getPendingDocuments: (): Observable<DocumentTestDto> => this.http.get<DocumentTestDto>(`${this.apiRelativePath}/Test/GetPendingDocuments`).pipe(catchError(this.handleError)),
		getPendingLink: (): Observable<LinkRequestDto> => this.http.get<LinkRequestDto>(`${this.apiRelativePath}/Test/GetPendingLink`).pipe(catchError(this.handleError)),
		getUser: (): Observable<UserTestDto> => this.http.get<UserTestDto>(`${this.apiRelativePath}/Test/GetUser`).pipe(catchError(this.handleError)),
		rollbackChanges: (auditCheckpoint: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Test/RollbackChanges?auditCheckpoint=${auditCheckpoint}`).pipe(catchError(this.handleError)),
	};
	toolkit = {
		deleteToolkit: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Toolkit/DeleteToolkit`, id).pipe(catchError(this.handleError)),
		getApprovedLinks: (): Observable<ApprovedLinkDto[]> => this.http.get<ApprovedLinkDto[]>(`${this.apiRelativePath}/Toolkit/GetApprovedLinks`).pipe(catchError(this.handleError)),
		getImagePreview: (id: number): Observable<any> => this.http.get<any>(`${this.apiRelativePath}/Toolkit/GetImagePreview?id=${id}`).pipe(catchError(this.handleError)),
		getToolkits: (): Observable<ToolkitDto[]> => this.http.get<ToolkitDto[]>(`${this.apiRelativePath}/Toolkit/GetToolkits`).pipe(catchError(this.handleError)),
		saveToolkit: (request: SaveToolkitRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/Toolkit/SaveToolkit`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		uploadImage: (): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/Toolkit/UploadImage`, null).pipe(catchError(this.handleError)),
	};
	userBookmark = {
		getMyBookmarks: (): Observable<QuickLink[]> => this.http.get<QuickLink[]>(`${this.apiRelativePath}/UserBookmark/GetMyBookmarks`).pipe(catchError(this.handleError)),
		save: (request: SaveUserBookmarkRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/UserBookmark/Save`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};
	user = {
		deleteUser: (id: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/User/DeleteUser`, id).pipe(catchError(this.handleError)),
		getAllPermissions: (): Observable<PermissionDto[]> => this.http.get<PermissionDto[]>(`${this.apiRelativePath}/User/GetAllPermissions`).pipe(catchError(this.handleError)),
		getCurrentUser: (): Observable<CurrentUserDto> => this.http.get<CurrentUserDto>(`${this.apiRelativePath}/User/GetCurrentUser`).pipe(catchError(this.handleError)),
		getMyUploads: (userId: number): Observable<MyUploadsResultList> => this.http.get<MyUploadsResultList>(`${this.apiRelativePath}/User/GetMyUploads?userId=${userId}`).pipe(catchError(this.handleError)),
		getUsers: (request: GetUsersRequest): Observable<UserListDto> => this.http.post<UserListDto>(`${this.apiRelativePath}/User/GetUsers`, request).pipe(catchError(this.handleError)),
		saveUser: (request: SaveUserRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/User/SaveUser`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
		saveUserLifecycleGroup: (lifecycleGroupId: number): Observable<any> => this.http.post<any>(`${this.apiRelativePath}/User/SaveUserLifecycleGroup`, lifecycleGroupId).pipe(catchError(this.handleError)),
		updateUserEmail: (request: UpdateUserEmailRequest): Observable<number> => this.http.post(`${this.apiRelativePath}/User/UpdateUserEmail`, request, { responseType: 'text' }).pipe(map( x => Number(x))).pipe(catchError(this.handleError)),
	};

	private handleError(error: HttpErrorResponse | any) {
		let errMsg: string;
		if (error instanceof HttpErrorResponse) {
			const hdr = error.headers.get('ExceptionMessage');
			errMsg = `${error.status}${hdr ? ' - ' + hdr : ''} - ${error.statusText || ''} ${error.error}`;
		} else {
			errMsg = error.message ? error.message : error.toString();
		}
		console.error(errMsg);
		return Observable.throw(errMsg);
	}
}
