import { DocumentToAccessLevel } from './DocumentToAccessLevel';
import { LinkToAccessLevel } from './LinkToAccessLevel';
import { UserAccessLevel } from './UserAccessLevel';

export class AccessLevel {
	public Description: string ;
	public Documents: DocumentToAccessLevel[]  = [];
	public Enabled: boolean ;
	public Id: number ;
	public Links: LinkToAccessLevel[]  = [];
	public Name: string ;
	public UserAccessLevels: UserAccessLevel[]  = [];

	public constructor(
		fields?: Partial<AccessLevel>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new DocumentToAccessLevel(x)); }
			if (fields.Links) { fields.Links = fields.Links.map(x => new LinkToAccessLevel(x)); }
			if (fields.UserAccessLevels) { fields.UserAccessLevels = fields.UserAccessLevels.map(x => new UserAccessLevel(x)); }
			Object.assign(this, fields);
		}
	}
}
