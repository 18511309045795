
export class LifeCycleItemDto {
	public Description: string ;
	public Id: number ;
	public LifeCycleGroupId: number ;
	public LifeCycleId: number ;
	public Name: string ;
	public Ordinal: number ;

	public constructor(
		fields?: Partial<LifeCycleItemDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
