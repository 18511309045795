import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  DataService,
  CurrentUserDto,
  ItemType,
  QuickLink,
  SaveItemViewRequest,
  ItemViewLocation,
  SearchRequest,
} from '../models/generated';
import { Router } from '@angular/router';
import { BookmarksService } from '../services/bookmarks.service';
import { SearchService } from '../services/search.service';
import { FileObjectDto } from '../models/FileObjectDto';
import { PreviewDocumentComponent } from '../admin/preview-document/preview-document.component';
import { MatDialog } from '@angular/material';
import { FileObjectDownloader } from '../home/fileObjectHelper';
import { ItemViewService } from '../services/itemView.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit, OnDestroy {
  private searchSubscription: Subscription;

  currentUser: CurrentUserDto = new CurrentUserDto();
  searchTerm: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    public bookmarksService: BookmarksService,
    private searchService: SearchService,
    private matDialog: MatDialog,
    private downloader: FileObjectDownloader,
    private itemViewService: ItemViewService,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
  }

  async ngOnInit() {
    this.setCurrentUser(this.userService.cu);
    this.searchSubscription = this.searchService.subscribe(
      // Update the current search text
      (req: SearchRequest) => (this.searchTerm = (req || new SearchRequest()).Term)
    );
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = null;
    }
  }

  search() {
    this.searchService.setSearchTerm(this.searchTerm);
  }

  bookmarkClicked(item: QuickLink) {
    if (item.ItemType === ItemType.Document) {
      this.openDocumentPreview(item);
    } else {
      window.open(item.Url, '_blank');
    }
  }

  async openDocumentPreview(item: QuickLink) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.Bookmarks }));
    const fileObject = new FileObjectDto({
      Id: item.Id,
      Name: item.CurrentVersionDocumentName,
      IsNavigator: false,
      DocumentVersionId: item.CurrentVersionId,
    });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  async openNavigatorPreview(item: QuickLink) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ NavigatorId: item.Id, Location: ItemViewLocation.Bookmarks }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.Name, IsNavigator: true });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  removeBookmark(b: QuickLink) {
    if (b.ItemType === ItemType.Document) {
      this.bookmarksService.saveUserBookmark(b.Id, null, null);
    } else if (b.ItemType === ItemType.Link) {
      this.bookmarksService.saveUserBookmark(null, b.Id, null);
    }
  }
  async linkClicked(item: QuickLink) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ LinkId: item.Id, Location: ItemViewLocation.Bookmarks }));
  }

  async download(item: QuickLink) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.Search }));
    this.downloader.downloadVersion(item.CurrentVersionId, item.CurrentVersionDocumentName);
  }

  goToUserProfile(): void {
    this.router.navigate(['/userProfile']);
  }

  removeSearchText() {
    this.searchTerm = '';
    this.searchService.setSearchTerm('');
  }
}
