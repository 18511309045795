import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { AddEditUserComponent } from './add-edit-user/add-edit-user.component';
import { ApproveContentComponent } from './approve-content/approve-content.component';
import { ManageLibrariesComponent } from './manage-libraries/manage-libraries.component';
import { SettingsComponent } from './settings/settings.component';
import { EditHomepageComponent } from './edit-homepage/edit-homepage.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MyUploadsComponent } from './my-uploads/my-uploads.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'approveContent',
    component: ApproveContentComponent,
  },
  {
    path: 'manageKeywords',
    component: ManageLibrariesComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'editHomepage',
    component: EditHomepageComponent,
  },
  {
    path: 'userProfile',
    component: UserProfileComponent,
  },
  {
    path: 'myUploads',
    component: MyUploadsComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [AddEditUserComponent, ApproveContentComponent],
})
export class AdminRoutingModule {}
