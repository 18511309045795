import { LifecycleGroupToLifecycle } from './LifecycleGroupToLifecycle';

export class LifecycleGroup {
	public Id: number ;
	public Lifecycles: LifecycleGroupToLifecycle[]  = [];
	public Name: string ;
	public Ordinal: number ;
	public SvgFileName: string ;
	public SvgFilePath: string ;
	public SvgForLifecycle: boolean ;

	public constructor(
		fields?: Partial<LifecycleGroup>) {

		if (fields) {


			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new LifecycleGroupToLifecycle(x)); }
			Object.assign(this, fields);
		}
	}
}
