
export class Permission {
	public static readonly ContentAdmin: string = 'ContentAdmin';
	public static readonly SiteAdmin: string = 'SiteAdmin';
	public static readonly Uploader: string = 'Uploader';

	public Code: string ;
	public Description: string ;
	public Name: string ;

	public constructor(
		fields?: Partial<Permission>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
