import { Component, OnInit } from '@angular/core';
import { DataService, QuickLinksDto, QuickLink, SaveItemViewRequest } from '../../models/generated';
import { FileObjectDto } from '../../models/FileObjectDto';
import { PreviewDocumentComponent } from '../../admin/preview-document/preview-document.component';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { ItemViewService } from '../../services/itemView.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  public quickLinks: QuickLinksDto = new QuickLinksDto();
  public top20QuickLinks: QuickLinksDto = new QuickLinksDto();
  public top7QuickLinks: QuickLinksDto = new QuickLinksDto();

  public onlyShow7 = true;
  loading = true;
  constructor(private matDialog: MatDialog, private itemViewService: ItemViewService) {
    this.itemViewService.quickLinks$.subscribe(ql => {
      this.resetQuickLinks(ql);
    });
  }

  async ngOnInit() {
    this.loading = false;
    this.resetQuickLinks(this.itemViewService.ql);
  }

  resetQuickLinks(ql: QuickLinksDto) {
    if (!ql) {
      return;
    }
    this.top20QuickLinks = ql;
    this.top7QuickLinks = new QuickLinksDto();
    this.top7QuickLinks.Bookmarked = this.top20QuickLinks.Bookmarked.slice(0, 7);
    this.top7QuickLinks.MostVisited = this.top20QuickLinks.MostVisited.slice(0, 7);
    this.top7QuickLinks.NewUploads = this.top20QuickLinks.NewUploads.slice(0, 7);
    this.top7QuickLinks.RecentlyViewed = this.top20QuickLinks.RecentlyViewed.slice(0, 7);

    if (this.onlyShow7) {
      this.quickLinks = this.top7QuickLinks;
    } else {
      this.quickLinks = this.top20QuickLinks;
    }
  }

  showTop7() {
    this.onlyShow7 = true;
    this.resetQuickLinks(this.itemViewService.ql);
  }

  showTop20() {
    this.onlyShow7 = false;
    this.resetQuickLinks(this.itemViewService.ql);
  }

  linkClicked(item: QuickLink, location: string) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ LinkId: item.Id, Location: location }));
  }

  async openDocument(item: QuickLink, location: string) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: location }));
    const fileObject = new FileObjectDto({
      Id: item.Id,
      Name: item.CurrentVersionDocumentName,
      IsNavigator: false,
      DocumentVersionId: item.CurrentVersionId,
    });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  async openNavigator(item: QuickLink, location: string) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ NavigatorId: item.Id, Location: location }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.Name, IsNavigator: true });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  getRelativeTime(item: QuickLink) {
    return moment(item.Date).fromNow();
  }
}
