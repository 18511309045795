import * as moment from 'moment';

export class DocumentVersionDto {
	public CreatedByFirstName: string ;
	public CreatedByLastName: string ;
	public CreatedDate: moment.Moment ;
	public DocumentId: number ;
	public DocumentName: string ;
	public DocumentStatusTypeCode: string ;
	public FilePath: string ;
	public Id: number ;
	public PreviewPath: string ;

	public constructor(
		fields?: Partial<DocumentVersionDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }

			Object.assign(this, fields);
		}
	}
}
