
export class ItemType {
	public static readonly Document: string = 'DOC';
	public static readonly Link: string = 'LINK';


	public constructor(
		fields?: Partial<ItemType>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
