import { Lifecycle } from './Lifecycle';
import { Link } from './Link';

export class LinkToLifecycle {
	public Id: number ;
	public Lifecycle: Lifecycle ;
	public LifecycleId: number ;
	public Link: Link ;
	public LinkId: number ;

	public constructor(
		fields?: Partial<LinkToLifecycle>) {

		if (fields) {
			if (fields.Lifecycle) { fields.Lifecycle = new Lifecycle(fields.Lifecycle); }
			if (fields.Link) { fields.Link = new Link(fields.Link); }


			Object.assign(this, fields);
		}
	}
}
