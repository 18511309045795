import { DocumentToLifecycle } from './DocumentToLifecycle';
import { LinkToLifecycle } from './LinkToLifecycle';

export class Lifecycle {
	public Description: string ;
	public Documents: DocumentToLifecycle[]  = [];
	public Enabled: boolean ;
	public Id: number ;
	public Links: LinkToLifecycle[]  = [];
	public Name: string ;
	public Ordinal: number ;

	public constructor(
		fields?: Partial<Lifecycle>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new DocumentToLifecycle(x)); }
			if (fields.Links) { fields.Links = fields.Links.map(x => new LinkToLifecycle(x)); }
			Object.assign(this, fields);
		}
	}
}
