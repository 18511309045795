
export class BookmarkedLinkDto {
	public BookmarkId: number ;
	public LinkId: number ;
	public Name: string ;
	public Url: string ;

	public constructor(
		fields?: Partial<BookmarkedLinkDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
