import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatFormFieldModule,
  MatOptionModule,
  MatMenuModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatTabsModule,
  MatTableModule,
  MatDatepickerModule,
  MAT_DATE_FORMATS,
  MatPaginatorModule,
  MatStepperModule,
  MatSnackBarModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatDividerModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatSortModule,
  MatTreeModule,
  MatListModule,
  MatChipsModule,
  MatSliderModule,
  MatGridListModule,
} from '@angular/material';

import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatMenuModule,
    MatSidenavModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatStepperModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatTreeModule,
    MatListModule,
    MatChipsModule,
    MatSidenavModule,
    MatGridListModule,
  ],
  exports: [
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatMenuModule,
    MatSidenavModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatStepperModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDividerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatTreeModule,
    MatListModule,
    MatChipsModule,
    MatSidenavModule,
    MatGridListModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class MaterialModule {}
