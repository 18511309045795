
export class SaveItemViewRequest {
	public DocumentId: number ;
	public LinkId: number ;
	public Location: string ;
	public NavigatorId: number ;
	public UserId: number ;

	public constructor(
		fields?: Partial<SaveItemViewRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
