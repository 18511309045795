import { Directive, ElementRef, OnDestroy, HostListener, HostBinding, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[keyboardClick]',
})
export class KeyboardClickDirective implements OnDestroy, OnInit {
  eventListenerRef: any;
  @HostBinding('attr.tabindex') tabIndexValue: number;

  // tslint:disable-next-line:no-output-rename
  @Output('click')
  emiter = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    // console.log('this should have a click event', elementRef);
  }

  ngOnInit(): void {
    if (!this.tabIndexValue) {
      this.tabIndexValue = 1;
    }
  }

  @HostListener('keydown', ['$event'])
  enterPressed(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.emiter.emit(e);
    }
  }

  ngOnDestroy(): void {}
}
