
export class UpdateApprovalItemRequest {
	public comment: string ;
	public ItemId: number ;
	public ItemName: string ;
	public ItemType: string ;
	public Status: string ;
	public UploaderId: number ;
	public UserId: number ;

	public constructor(
		fields?: Partial<UpdateApprovalItemRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
