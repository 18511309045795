import { Component, OnInit } from '@angular/core';
import { DataService, CurrentUserDto, UpdateUserEmailRequest } from '../../models/generated';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  currentUser: CurrentUserDto = new CurrentUserDto();
  constructor(private dataService: DataService, private snackBar: MatSnackBar, private userService: UserService) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
  }

  async ngOnInit() {
    this.setCurrentUser(this.userService.cu);
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  updateEmailForUser() {
    const updateUserEmailRequest = new UpdateUserEmailRequest();
    updateUserEmailRequest.Id = this.currentUser.Id;
    updateUserEmailRequest.Email = this.currentUser.Email;
    return this.dataService.user.updateUserEmail(updateUserEmailRequest).subscribe(result => {
      this.snackBar.open('User email has been updated', '', {
        duration: 1500,
      });
    });
  }
}
