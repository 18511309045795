
export class LinkToLifecycleDto {
	public Id: number ;
	public LifecycleId: number ;
	public LinkId: number ;

	public constructor(
		fields?: Partial<LinkToLifecycleDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
