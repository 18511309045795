
export class SaveToolkitRequest {
	public Id: number ;
	public ImagePath: string ;
	public LinkId: number ;
	public Title: string ;
	public Url: string ;

	public constructor(
		fields?: Partial<SaveToolkitRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
