import { AccessLevel } from './AccessLevel';
import { Link } from './Link';

export class LinkToAccessLevel {
	public AccessLevel: AccessLevel ;
	public AccessLevelId: number ;
	public Id: number ;
	public Link: Link ;
	public LinkId: number ;

	public constructor(
		fields?: Partial<LinkToAccessLevel>) {

		if (fields) {
			if (fields.AccessLevel) { fields.AccessLevel = new AccessLevel(fields.AccessLevel); }
			if (fields.Link) { fields.Link = new Link(fields.Link); }


			Object.assign(this, fields);
		}
	}
}
