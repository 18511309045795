import { LifecycleGroupToLifecycleTestDto } from './LifecycleGroupToLifecycleTestDto';

export class LifecycleGroupTestDto {
	public Id: number ;
	public LifecycleGroupToLifecycle: LifecycleGroupToLifecycleTestDto[]  = [];
	public Name: string ;

	public constructor(
		fields?: Partial<LifecycleGroupTestDto>) {

		if (fields) {


			if (fields.LifecycleGroupToLifecycle) { fields.LifecycleGroupToLifecycle = fields.LifecycleGroupToLifecycle.map(x => new LifecycleGroupToLifecycleTestDto(x)); }
			Object.assign(this, fields);
		}
	}
}
