import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { AdminModule } from './admin/admin.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService, ItemViewLocation } from './models/generated';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { MaterialModule } from './material.module';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { FormsModule } from '@angular/forms';
import { BookmarksService } from './services/bookmarks.service';
import { SearchService } from './services/search.service';
import { SharedModule } from './shared.module';
import { ItemViewService } from './services/itemView.service';
import { MyUploadsService } from './services/my-uploads.service';

@NgModule({
  declarations: [AppComponent, TopNavbarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    AdminModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    SharedModule,
  ],
  exports: [],
  providers: [
    DataService,
    BookmarksService,
    ItemViewService,
    SearchService,
    MyUploadsService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
