import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { ApprovedLinkDto, DataService, ToolkitDto, SaveToolkitRequest } from '../../models/generated';
import { FileUploader } from 'ng2-file-upload';
import { MAX_FILE_SIZE } from '../../utils/constants';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'edit-toolkit-modal',
  templateUrl: './edit-toolkit-modal.component.html',
  styleUrls: ['./edit-toolkit-modal.component.scss'],
})
export class EditToolkitModalComponent implements OnInit {
  modalTitle: string;
  toolkit: ToolkitDto;
  toolkitList: ToolkitDto[];
  links: ApprovedLinkDto[] = [];
  selected: string;
  showUploader = true;
  editToolkitImage = false;
  editModal = false;
  toolkitUploader: FileUploader = new FileUploader({
    url: 'api/Toolkit/UploadImage',
    autoUpload: false,
    removeAfterUpload: true,
    maxFileSize: MAX_FILE_SIZE,
    queueLimit: 1,
  });
  filePreviewPath: SafeUrl;
  fileUploadErrorMessage: string;
  static open(matDialog: MatDialog, toolkit: ToolkitDto): Promise<any> {
    return matDialog
      .open(EditToolkitModalComponent, {
        width: '800px',
        data: toolkit,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<EditToolkitModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ToolkitDto,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) {
    this.toolkitUploader.onAfterAddingFile = fileItem => {
      if (!fileItem.file.type.includes('image')) {
        this.updateErrorMessage('please only upload an image for toolkits.');
        this.removeImage();
      } else {
        this.showUploader = false;
        this.filePreviewPath = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(fileItem._file));
      }
    };
  }

  ngOnInit() {
    this.toolkit = this.data;
    this.setModalTitle();
    this.getApprovedLinks();
  }

  removeImage() {
    this.toolkitUploader.queue = [];
    this.filePreviewPath = null;
    this.showUploader = true;
  }

  updateErrorMessage(message: string) {
    this.fileUploadErrorMessage = message;
  }

  getApprovedLinks() {
    return this.dataService.toolkit
      .getApprovedLinks()
      .toPromise()
      .then(result => {
        this.links = result;
        if (this.toolkit.Id) {
          this.selected = this.links.find(x => x.Url.includes(this.toolkit.Url)).Url;
        }
      });
  }

  selectedUrl() {
    this.toolkit.Url = this.selected;
    this.toolkit.Title = this.links.find(x => x.Url.includes(this.toolkit.Url)).Name;
    this.toolkit.LinkId = this.links.find(x => x.Url.includes(this.toolkit.Url)).Id;
  }

  setModalTitle() {
    if (this.toolkit.Id) {
      this.modalTitle = 'Edit Toolkit';
      this.showUploader = false;
      this.editToolkitImage = true;
      this.editModal = true;
    } else {
      this.modalTitle = 'Add Toolkit';
    }
  }

  async getToolkits() {
    this.toolkitList = await this.dataService.toolkit.getToolkits().toPromise();
  }

  async docAdded(doc) {
    const toolkit = new SaveToolkitRequest((<any>doc).uploaderItem);
    toolkit.Title = this.toolkit.Title;
    toolkit.Url = this.toolkit.Url;
    toolkit.LinkId = this.toolkit.LinkId;

    if (this.editModal) {
      await this.getToolkits();
      toolkit.Id = this.toolkit.Id;
      toolkit.ImagePath = this.toolkitList.find(x => x.Id === doc.Id).ImagePath;
      this.dataService.toolkit.saveToolkit(toolkit).subscribe(result => {
        this.onClose();
        this.snackBar.open('The Toolkit was saved!', '', {
          duration: 1500,
        });
        this.dataService.toolkit.deleteToolkit(doc.Id).subscribe();
      });
    } else {
      toolkit.Id = doc.Id;
      this.dataService.toolkit.saveToolkit(toolkit).subscribe(result => {
        this.onClose();
        this.snackBar.open('The Toolkit was saved!', '', {
          duration: 1500,
        });
      });
    }
  }

  saveToolkit() {
    if (this.toolkitUploader.queue.length < 1) {
      this.dataService.toolkit.saveToolkit(this.toolkit).subscribe(result => {
        this.onClose();
        this.snackBar.open('The Toolkit was saved!', '', {
          duration: 1500,
        });
      });
    }
    this.toolkitUploader.uploadAll();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isDisabled() {
    if (!this.toolkit.Id) {
      return this.toolkitUploader.queue.length < 1 || !this.selected || !this.toolkit.Title;
    } else {
      return !this.toolkit.Title;
    }
  }

  deleteSavedImage() {
    this.editToolkitImage = false;
    this.showUploader = true;
  }
}
