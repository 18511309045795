import { Permission } from './Permission';
import { User } from './User';

export class UserPermission {
	public Id: number ;
	public Permission: Permission ;
	public PermissionCode: string ;
	public User: User ;
	public UserId: number ;

	public constructor(
		fields?: Partial<UserPermission>) {

		if (fields) {
			if (fields.Permission) { fields.Permission = new Permission(fields.Permission); }
			if (fields.User) { fields.User = new User(fields.User); }


			Object.assign(this, fields);
		}
	}
}
