import * as moment from 'moment';

export class NewsDto {
	public Description: string ;
	public EndDate: moment.Moment ;
	public Id: number ;
	public ImagePath: string ;
	public Published: boolean ;
	public StartDate: moment.Moment ;
	public Title: string ;

	public constructor(
		fields?: Partial<NewsDto>) {

		if (fields) {

			if (fields.EndDate) { fields.EndDate = moment(fields.EndDate); }
			if (fields.StartDate) { fields.StartDate = moment(fields.StartDate); }

			Object.assign(this, fields);
		}
	}
}
