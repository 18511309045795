
export class UserPermissionDto {
	public Code: string ;
	public Name: string ;

	public constructor(
		fields?: Partial<UserPermissionDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
