import { LifecycleGroup } from './LifecycleGroup';
import { UserAccessLevel } from './UserAccessLevel';
import { UserPermission } from './UserPermission';

export class User {
	public Email: string ;
	public Enabled: boolean ;
	public FirstName: string ;
	public Id: number ;
	public LastName: string ;
	public LifecycleGroup: LifecycleGroup ;
	public LifecycleGroupId: number ;
	public UserAccessLevels: UserAccessLevel[]  = [];
	public UserName: string ;
	public UserPermissions: UserPermission[]  = [];

	public constructor(
		fields?: Partial<User>) {

		if (fields) {
			if (fields.LifecycleGroup) { fields.LifecycleGroup = new LifecycleGroup(fields.LifecycleGroup); }

			if (fields.UserAccessLevels) { fields.UserAccessLevels = fields.UserAccessLevels.map(x => new UserAccessLevel(x)); }
			if (fields.UserPermissions) { fields.UserPermissions = fields.UserPermissions.map(x => new UserPermission(x)); }
			Object.assign(this, fields);
		}
	}
}
