import { Component, OnInit } from '@angular/core';
import { NewsDto, DataService } from '../../models/generated';
import * as moment from 'moment';
import { NewsModalComponent } from '../news-modal/news-modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  newsData: NewsDto[] = [];
  index = 0;
  speed = 5000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;
  today = moment();
  placeholder = 'No Description';
  showLessDescription = false;
  expandedIndex: number;
  loading = true;

  constructor(private dataService: DataService, public matDialog: MatDialog) {}

  ngOnInit() {
    this.getNews();
  }

  async getNews() {
    this.dataService.news.getNews().subscribe(results => {
      this.newsData = results.filter(
        x =>
          x.Published &&
          (!x.StartDate || (this.today.isSameOrAfter(x.StartDate) && (!x.EndDate || moment(x.EndDate).isSameOrAfter(this.today))))
      );
      this.newsData.sort((a, b) => Number(moment(b.StartDate)) - Number(moment(a.StartDate)));
      this.loading = false;
    });
  }

  indexChanged(index) {
    console.log(index);
  }

  toggleDirection($event) {
    this.direction = this.directionToggle ? 'right' : 'left';
  }

  isOverflown(i: number) {
    const description = 'description-' + i;
    const element = document.getElementById(description);
    if (element) {
      if (element.offsetHeight < element.scrollHeight) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  async showMore(news) {
    this.autoplay = false;
    await NewsModalComponent.open(this.matDialog, news);
    this.autoplay = true;
  }
}
