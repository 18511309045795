
export class UpdateDeleteLinkRequest {
	public Id: number ;

	public constructor(
		fields?: Partial<UpdateDeleteLinkRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
