import { Injectable } from '@angular/core';
import { BehaviorSubject, PartialObserver } from 'rxjs';
import { SearchRequest } from '../models/generated';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class SearchService {
  private searchRequest = new BehaviorSubject<SearchRequest>(null);

  public pageSize = 25;
  public pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.searchRequest.subscribe(this.handleSearchRequest.bind(this));
  }

  get currentSearchRequest(): SearchRequest {
    if (!this.searchRequest.value) {
      return new SearchRequest();
    }
    return this.searchRequest.value;
  }

  subscribe(observer) {
    return this.searchRequest.subscribe(observer);
  }

  setSearchTerm(term: string) {
    const request = this.createOrUpdateRequest(term || '');
    this.searchRequest.next(request);
  }

  search(request: SearchRequest) {
    this.searchRequest.next(request);
  }

  reset() {
    this.searchRequest.next(null);
  }

  private createOrUpdateRequest(term: string): SearchRequest {
    if (!this.currentSearchRequest) {
      return new SearchRequest({
        Start: 0,
        Count: 25,
        Lifecycles: [],
        Roles: [],
        Tags: [],
        ItemTypes: [],
        SortByNewest: true,
        ShowDeleted: false,
        Term: term,
      });
    }
    return { ...this.currentSearchRequest, Term: term };
  }

  private handleSearchRequest(request: SearchRequest) {
    if (!request) {
      return;
    }

    if (window.location.href.indexOf('/search') > -1) {
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: this.prepareParams(request), replaceUrl: true });
    } else {
      this.router.navigate(['/search'], { queryParams: this.prepareParams(request) });
    }
  }

  private prepareParams(request: SearchRequest) {
    request = { ...request }; // Work on a copy
    if (Array.isArray(request.ItemTypes) && request.ItemTypes.length === 0) {
      delete request.ItemTypes;
    }
    if (Array.isArray(request.Lifecycles) && request.Lifecycles.length === 0) {
      delete request.Lifecycles;
    }
    if (Array.isArray(request.Roles) && request.Roles.length === 0) {
      delete request.Roles;
    }
    if (Array.isArray(request.Tags) && request.Tags.length === 0) {
      delete request.Tags;
    }
    if (!request.ShowDeleted) {
      delete request.ShowDeleted;
    }
    return request;
  }
}
