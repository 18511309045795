import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditToolkitModalComponent } from '../edit-toolkit-modal/edit-toolkit-modal.component';
import { DataService, ToolkitDto } from '../../models/generated';

@Component({
  selector: 'edit-toolkit',
  templateUrl: './edit-toolkit.component.html',
  styleUrls: ['./edit-toolkit.component.scss'],
})
export class EditToolkitComponent implements OnInit {
  toolkits: ToolkitDto[] = [];
  imgCounter = 0;
  loading = true;

  constructor(private matDialog: MatDialog, private dataService: DataService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getToolkits();
  }

  async getToolkits() {
    await this.dataService.toolkit.getToolkits().subscribe(results => {
      this.toolkits = results;
      this.loading = false;
    });
  }

  async openEditToolkit(toolkit: ToolkitDto) {
    if (!toolkit) {
      toolkit = new ToolkitDto();
    }
    EditToolkitModalComponent.open(this.matDialog, toolkit).finally(() => {
      this.getToolkits();
      this.imgCounter++;
    });
  }

  deleteTookit(toolkit: ToolkitDto) {
    this.dataService.toolkit.deleteToolkit(toolkit.Id).subscribe(result => {
      this.getToolkits();
    });
    this.snackBar
      .open('Toolkit Deleted.', 'Undo', {
        duration: 3000,
      })
      .onAction()
      .subscribe(() => {
        toolkit.Id = null;
        this.dataService.toolkit.saveToolkit(toolkit).subscribe(result => {
          this.getToolkits();
        });
        this.getToolkits();
      });
  }

  imgPreview(id: number) {
    return 'api/Toolkit/GetImagePreview/' + id + '?' + this.imgCounter;
  }
}
