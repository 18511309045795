import { DocumentVersionTestDto } from './DocumentVersionTestDto';

export class BookmarkedDocumentDto {
	public BookmarkId: number ;
	public DocumentId: number ;
	public Name: string ;
	public Versions: DocumentVersionTestDto[]  = [];

	public constructor(
		fields?: Partial<BookmarkedDocumentDto>) {

		if (fields) {


			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionTestDto(x)); }
			Object.assign(this, fields);
		}
	}
}
