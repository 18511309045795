
export class SearchResultTag {
	public Id: number ;
	public Name: string ;

	public constructor(
		fields?: Partial<SearchResultTag>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
