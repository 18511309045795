
export class ApplicationValue {
	public static readonly APP_URL: string = 'AppUrl';
	public static readonly CONTENT_APPROVAL: string = 'ContentApproval';
	public static readonly DOCUMENTS_DIRECTORY: string = 'DocumentsDirectory';
	public static readonly MAIL_FROM: string = 'MailFrom';
	public static readonly SMTP_HOST: string = 'SmtpHost';
	public static readonly SMTP_PASSWORD: string = 'SmtpPassword';
	public static readonly SMTP_PORT: string = 'SmtpPort';
	public static readonly SMTP_USER: string = 'SmtpUser';

	public Id: string ;
	public Value: string ;

	public constructor(
		fields?: Partial<ApplicationValue>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
