import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LifecycleGroupsDto } from '../../models/generated';

@Component({
  selector: 'app-lifecycle-details',
  templateUrl: './lifecycle-details.component.html',
  styleUrls: ['./lifecycle-details.component.scss'],
})
export class LifecycleDetailsComponent implements OnInit {
  group: LifecycleGroupsDto;
  static open(matDialog: MatDialog, group: LifecycleGroupsDto): Promise<any> {
    return matDialog
      .open(LifecycleDetailsComponent, {
        width: '800px',
        data: group,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(public dialogRef: MatDialogRef<LifecycleDetailsComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit() {
    this.group = this.data;
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
