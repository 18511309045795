
export class SaveUserRequest {
	public AccessLevels: number[]  = [];
	public Email: string ;
	public Enabled: boolean ;
	public FirstName: string ;
	public Id: number ;
	public LastName: string ;
	public Permissions: string[]  = [];
	public UserName: string ;

	public constructor(
		fields?: Partial<SaveUserRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
