import { DocumentToTag } from './DocumentToTag';
import { LinkToTag } from './LinkToTag';

export class Tag {
	public Documents: DocumentToTag[]  = [];
	public Enabled: boolean ;
	public Id: number ;
	public Links: LinkToTag[]  = [];
	public Name: string ;

	public constructor(
		fields?: Partial<Tag>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new DocumentToTag(x)); }
			if (fields.Links) { fields.Links = fields.Links.map(x => new LinkToTag(x)); }
			Object.assign(this, fields);
		}
	}
}
