import * as moment from 'moment';

export class LinkRequestDto {
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public Description: string ;
	public Id: number ;
	public LinkStatusTypeCode: string ;
	public Name: string ;
	public Url: string ;

	public constructor(
		fields?: Partial<LinkRequestDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }

			Object.assign(this, fields);
		}
	}
}
