import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentUserDto, DataService } from '../models/generated';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser = new BehaviorSubject<CurrentUserDto>(null);
  currentUser$ = this.currentUser.asObservable();
  public cu: CurrentUserDto;
  constructor(private dataService: DataService) {
    this.getCurrentUser();
  }

  async getCurrentUser() {
    this.cu = await this.dataService.user.getCurrentUser().toPromise();
    this.currentUser.next(this.cu);
  }
}
