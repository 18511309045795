import { AccessLevel } from './AccessLevel';
import { User } from './User';

export class UserAccessLevel {
	public AccessLevel: AccessLevel ;
	public AccessLevelId: number ;
	public Id: number ;
	public User: User ;
	public UserId: number ;

	public constructor(
		fields?: Partial<UserAccessLevel>) {

		if (fields) {
			if (fields.AccessLevel) { fields.AccessLevel = new AccessLevel(fields.AccessLevel); }
			if (fields.User) { fields.User = new User(fields.User); }


			Object.assign(this, fields);
		}
	}
}
