
export class PermissionDto {
	public Code: string ;
	public Description: string ;
	public Name: string ;

	public constructor(
		fields?: Partial<PermissionDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
