import * as moment from 'moment';
import { DocumentVersionDto } from './DocumentVersionDto';
import { SearchResultTag } from './SearchResultTag';

export class ApprovalItemDto {
	public AllTags: SearchResultTag[]  = [];
	public Comment: string ;
	public CreatedDate: moment.Moment ;
	public Deleted: boolean ;
	public Id: number ;
	public ItemDescription: string ;
	public ItemName: string ;
	public ItemStatus: string ;
	public ItemType: string ;
	public LifeCycles: SearchResultTag[]  = [];
	public PreviewPath: string ;
	public Roles: SearchResultTag[]  = [];
	public Tags: SearchResultTag[]  = [];
	public UploaderId: number ;
	public URL: string ;
	public Versions: DocumentVersionDto[]  = [];

	public constructor(
		fields?: Partial<ApprovalItemDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.AllTags) { fields.AllTags = fields.AllTags.map(x => new SearchResultTag(x)); }
			if (fields.LifeCycles) { fields.LifeCycles = fields.LifeCycles.map(x => new SearchResultTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new SearchResultTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new SearchResultTag(x)); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionDto(x)); }
			Object.assign(this, fields);
		}
	}
}
