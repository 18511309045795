import { Component, OnInit, ViewChild } from '@angular/core';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { DataService, UserDto, GetUsersRequest, AccessLevelDto } from '../../models/generated';
import { EditAccessLevelModalComponent } from '../edit-access-level-modal/edit-access-level-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: UserDto[];
  accessLevels: AccessLevelDto[];
  loading = true;
  length = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageIndex = 0;
  term: string;
  searchTimeout: any;
  displayedColumns = ['Name', 'Enabled', 'Email', 'UserName', 'Permissions'];
  displayedColumnsForAccessLevel = ['Name', 'Enabled', 'Description'];

  enabled = 'enabled';
  disabled = 'disabled';
  all = 'all';
  selected = this.enabled;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private matDialog: MatDialog, private dataService: DataService) {}

  ngOnInit() {
    this.term = '';
    this.getUsers(0);
    this.getAccessLevels();
  }

  async addOrEditUser(user: UserDto) {
    if (user === null) {
      user = new UserDto();
    }
    await AddEditUserComponent.open(this.matDialog, user);
    this.getUsers(0);
  }

  getUsers(timeout: number) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      // set this function separately for js tests
      this.usersDataCall();
    }, timeout);
  }

  async getAccessLevels() {
    this.accessLevels = await this.dataService.accessLevel.getAccessLevels().toPromise();
  }

  usersDataCall() {
    this.dataService.user
      .getUsers(
        new GetUsersRequest({
          Term: this.term,
          Start: this.pageIndex * this.pageSize,
          Count: this.pageSize,
          Status: this.selected,
        })
      )
      .subscribe(
        u => {
          if (this.selected === this.all) {
            this.users = u.UserDtos;
          } else if (this.selected === this.enabled) {
            this.users = u.UserDtos.filter(x => x.Enabled === true);
          } else {
            this.users = u.UserDtos.filter(x => x.Enabled === false);
          }
          this.length = u.Total;
          this.loading = false;
        },
        err => {
          console.log('There was an error getting the user list');
          this.users = new Array<UserDto>();
        }
      );
  }

  setSearch(newTerm: string) {
    this.pageIndex = 0;
    this.term = newTerm;
    this.getUsers(300);
  }

  setPaging(pagingEvent: PageEvent) {
    this.pageSize = pagingEvent.pageSize;
    this.pageIndex = pagingEvent.pageIndex;
    this.getUsers(0);
  }
  async addEditAccessLevel(level: AccessLevelDto) {
    if (level === null) {
      level = new AccessLevelDto();
      level.Enabled = true;
    }
    await EditAccessLevelModalComponent.open(this.matDialog, level);
    this.getAccessLevels();
  }

  selectedUserStatus() {
    return this.getUsers(0);
  }
}
