import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SearchRequest } from '../../models/generated/SearchRequest';
import {
  DataService,
  LibraryTag,
  EditDocumentDto,
  CurrentUserDto,
  SearchResult,
  ItemType,
  SaveItemViewRequest,
  ItemViewLocation,
  EditLinkDto,
  SaveLinkRequest,
  UpdateDocumentObjectRequest,
  UpdateDeleteLinkRequest,
} from '../../models/generated';
import { SearchResultList } from '../../models/generated/SearchResultList';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditDocumentComponent } from '../edit-document/edit-document.component';
import { FileObjectDto } from '../../models/FileObjectDto';
import { PreviewDocumentComponent } from '../../admin/preview-document/preview-document.component';
import { FileObjectDownloader } from '../fileObjectHelper';
import { BookmarksService } from '../../services/bookmarks.service';
import { SearchService } from '../../services/search.service';
import { AddLinkComponent } from '../add-link/add-link.component';
import { ItemViewService } from '../../services/itemView.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  private searchSubscription: Subscription;

  currentUser: CurrentUserDto = new CurrentUserDto();
  searchRequest: SearchRequest;
  searchResults: SearchResultList;
  lifecycles: LibraryTag[];
  roles: LibraryTag[];
  keywordTags: LibraryTag[];
  newest = 'newest';
  oldest = 'oldest';
  selected = this.newest;
  pageSize = 25;
  isMobile = false;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageIndex = 0;
  tempSearchTerm: string;
  loading = true;
  itemTypeDoc = ItemType.Document;
  itemTypeLink = ItemType.Link;
  url: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public matDialog: MatDialog,
    public downloader: FileObjectDownloader,
    public bookmarksService: BookmarksService,
    private searchService: SearchService,
    private itemViewService: ItemViewService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
  }

  async ngOnInit() {
    this.searchSubscription = this.searchService.subscribe(this.doSearch.bind(this));
    this.setCurrentUser(this.userService.cu);
    this.getTags();
    this.setupSearch();
    this.url = window.location.origin;
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  ngOnDestroy() {
    if (this.searchSubscription != null) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = null;
    }
    this.searchService.reset();
  }

  get showDeleted() {
    const searchRequest = this.searchService.currentSearchRequest;
    return !!searchRequest.ShowDeleted;
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  getTags() {
    this.dataService.libraries.getLibraryTags().subscribe(result => {
      this.lifecycles = result.Lifecycles;
      this.roles = result.Roles;
      this.keywordTags = result.Tags;
    });
  }

  setupSearch() {
    // Only invoke search if refreshing the search page so that
    // the search can load from the url query params.  All other
    // searches will start from the search service subscription.
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      const searchRequest = this.paramsToSearchRequest(params);
      this.searchService.search(searchRequest);
    });
  }

  paramsToSearchRequest(params: Params) {
    let tags = params['Tags'] || [];
    if (!Array.isArray(tags)) {
      tags = [tags];
    }

    let roles = params['Roles'] || [];
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    let lifecycles = params['Lifecycles'] || [];
    if (!Array.isArray(lifecycles)) {
      lifecycles = [lifecycles];
    }

    let items = params['ItemTypes'] || [];
    if (!Array.isArray(items)) {
      items = [items];
    }

    const searchRequest = new SearchRequest({
      Term: params['Term'],
      Count: params['Count'],
      Lifecycles: lifecycles.map(Number),
      Roles: roles.map(Number),
      Tags: tags.map(Number),
      ItemTypes: items,
      SortByNewest: params['SortByNewest'],
      Start: params['Start'],
      ShowDeleted: params['ShowDeleted'],
    });

    return searchRequest;
  }

  doSearch(request: SearchRequest) {
    if (!request) {
      return;
    }

    this.loading = true;
    this.dataService.search.search(request).subscribe(
      results => {
        this.searchResults = results;
      },
      err => {
        // Should we show an error message here?
      },
      () => {
        this.loading = false;
      }
    );
  }

  toggleValue(prop: keyof SearchRequest, value: string | number) {
    const searchRequest = { ...this.searchService.currentSearchRequest };
    if (!searchRequest[prop]) {
      searchRequest[prop] = [];
    }
    const list = <(string | number)[]>searchRequest[prop];
    const index = list.indexOf(value);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    this.searchService.search(searchRequest);
  }

  isChecked(prop: keyof SearchRequest, value: string | number) {
    const searchRequest = this.searchService.currentSearchRequest;
    const list = <(string | number)[]>searchRequest[prop] || [];
    return list.findIndex(x => x === value) >= 0;
  }

  onLifecycleChanged(l) {
    this.toggleValue('Lifecycles', l.Id);
  }

  isLifecycleChecked(l) {
    return this.isChecked('Lifecycles', l.Id);
  }

  onRoleChanged(r) {
    this.toggleValue('Roles', r.Id);
  }

  isRoleChecked(r) {
    return this.isChecked('Roles', r.Id);
  }

  onKeywordChanged(k) {
    this.toggleValue('Tags', k.Id);
  }

  isKeywordChecked(k) {
    return this.isChecked('Tags', k.Id);
  }

  onItemTypeChange(i: string) {
    this.toggleValue('ItemTypes', i);
  }

  isItemTypeChecked(i: string) {
    return this.isChecked('ItemTypes', i);
  }

  updateShowDeleted() {
    const searchRequest = this.searchService.currentSearchRequest;
    this.searchService.search({ ...searchRequest, ShowDeleted: !searchRequest.ShowDeleted });
  }

  updateSearchSort(selected: string) {
    const searchRequest = this.searchService.currentSearchRequest;
    this.searchService.search({ ...searchRequest, SortByNewest: selected === this.newest ? true : false });
  }

  isDeletedChecked(): boolean {
    const searchRequest = this.searchService.currentSearchRequest;
    return !!searchRequest.ShowDeleted;
  }

  clearFilters() {
    const searchRequest = this.searchService.currentSearchRequest;
    this.searchService.search({ ...searchRequest, Tags: [], Roles: [], Lifecycles: [], ItemTypes: [], ShowDeleted: false });
  }

  private refreshIfSuccessful(result: boolean) {
    if (result) {
      this.searchService.search({ ...this.searchService.currentSearchRequest });
      this.itemViewService.refresh();
    }
  }

  async openEditDocumentModal(documentId: number) {
    let document = new EditDocumentDto();
    await this.dataService.document.getEditDocument(documentId).subscribe(result => {
      document = result;
      EditDocumentComponent.open(this.matDialog, document).then(this.refreshIfSuccessful.bind(this));
    });
  }

  async openLinkModal(linkId: number) {
    const link = new SaveLinkRequest();
    await this.dataService.link
      .getEditLink(linkId)
      .toPromise()
      .then(result => {
        link.Id = result.Id;
        link.Description = result.Description;
        link.Name = result.Name;
        link.Roles = result.Roles;
        link.Tags = result.Tags;
        link.Lifecycles = result.Lifecycles;
        link.URL = result.URL;
        link.Deleted = result.Deleted;
        AddLinkComponent.open(this.matDialog, link).then(this.refreshIfSuccessful.bind(this));
      });
  }

  async bookmarkItem(item: SearchResult) {
    if (item.ItemType === ItemType.Document) {
      this.bookmarksService.saveUserBookmark(item.Id, null, null);
    } else if (item.ItemType === ItemType.Link) {
      this.bookmarksService.saveUserBookmark(null, item.Id, null);
    }
  }

  isBookmarked(item: SearchResult): Boolean {
    return this.bookmarksService.isBookmarked(item.Id, item.ItemType);
  }

  async previewDocument(item: SearchResult) {
    const documentName = item.Versions.find(v => v.Id === item.CurrentVersionId).DocumentName;
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.Search }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: documentName, IsNavigator: false, DocumentVersionId: item.CurrentVersionId });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  async previewNavigator(item: SearchResult) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ NavigatorId: item.Id, Location: ItemViewLocation.Search }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.Name, IsNavigator: true });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }
  async download(item: SearchResult) {
    const documentName = item.Versions.find(v => v.Id === item.CurrentVersionId).DocumentName;
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.Search }));
    this.downloader.downloadVersion(item.CurrentVersionId, documentName);
  }
  async linkClicked(item: SearchResult) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ LinkId: item.Id, Location: ItemViewLocation.Search }));
  }

  restoreDocument(document) {
    const updateDeleteDocument = new UpdateDocumentObjectRequest();
    updateDeleteDocument.Id = document.Id;
    this.dataService.document.updateDeleteDocument(updateDeleteDocument).subscribe(response => {
      this.snackBar.open('The document was restored!', '', {
        duration: 1500,
      });
      this.refreshIfSuccessful(true);
    });
  }

  restoreLink(link) {
    const updateDeleteLink = new UpdateDeleteLinkRequest();
    updateDeleteLink.Id = link.Id;
    this.dataService.link.updateDeleteLink(updateDeleteLink).subscribe(result => {
      this.snackBar.open('The link was restored!', '', {
        duration: 1500,
      });
      this.refreshIfSuccessful(true);
    });
  }
}
