import { Component, OnInit, OnDestroy, AfterViewInit, Output, Input, EventEmitter, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { MAX_FILE_SIZE } from '../utils/constants';
import { UploaderServiceListener, UploaderService } from './uploader.service';
import { MatSnackBar } from '@angular/material';
import { formatBytes } from '../home/fileObjectHelper';
import { SnackBarErrorComponent } from '../home/snack-bar-error/snack-bar-error.component';

@Component({
  selector: 'app-uploader',
  templateUrl: 'uploader.component.html',
  styleUrls: ['uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  documentAdded = new EventEmitter();
  @Output()
  documentsChanged = new EventEmitter();
  public documentsValue: any[] = [];
  @Output()
  fileUploadErrorMessage = new EventEmitter<string>();
  @Input()
  clearErrorMessage = false;
  currentErrorMessage: string;
  maxFileSize: number = MAX_FILE_SIZE;

  @ViewChild('inputId')
  fileSelectorInput: ElementRef;
  
  @Input()
  get documents() {
    return this.documentsValue;
  }
  set documents(val) {
    this.documentsValue = val;
    this.documentsChanged.emit(val);
  }
  @Input()
  uploader = new FileUploader({
    url: 'api/document/upload',
    autoUpload: false,
    removeAfterUpload: true,
    maxFileSize: MAX_FILE_SIZE,
    queueLimit: 1,
  });
  @Input()
  inputId: string;
  @Input()
  allowDragDrop = true;
  @Output()
  fileSelected = new EventEmitter();

  private uploadServiceListener: UploaderServiceListener;

  constructor(
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private uploaderService: UploaderService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.uploader.onCompleteItem = this.onComplete;
    this.uploader.onProgressItem = this.onProgressItem;
    this.uploader.onBeforeUploadItem = this.beforeUploadItem;
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
  }

  ngAfterViewInit() {
    const el = $(this.element.nativeElement);
    this.uploadServiceListener = this.uploaderService.registerOnDrag(event => {
      el.find('.drop-zone-wrapper').addClass('drag-start');
    });
    el.find('.drop-zone').on('dragleave', event => {
      el.find('.drop-zone-wrapper').removeClass('drag-start');
    });
    el.find('.drop-zone').on('drop', () => {
      el.find('.drop-zone-wrapper').removeClass('drag-start');
    });
  }

  ngOnDestroy() {
    if (this.uploadServiceListener) {
      this.uploadServiceListener.destory();
    }
  }

  fileOverBase(event) {}

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    if (this.clearErrorMessage) {
      this.currentErrorMessage = null;
      this.clearErrorMessage = false;
    }
    if (filter && filter.name === 'mimeType') {
      this.currentErrorMessage = `${item.name} does not have an allowed file type. Navigators must be .svg.`;
    } else if (filter && filter.name === 'fileSize') {
      this.currentErrorMessage =
        `${item.name} exceedes the maximum upload size. Must be less than ` +
        formatBytes(this.maxFileSize, 2) +
        `. File not added, size is:` +
        formatBytes(item.size, 2) +
        `.`;
    } else {
      this.currentErrorMessage = 'An error has occurred. ';
    }

    this.fileUploadErrorMessage.emit(this.currentErrorMessage);
  }

  onComplete = item => {
    if (item && item._xhr && item._xhr.response !== '') {
      const doc = JSON.parse(item._xhr.response)[0];
      if (doc) {
        doc.uploaderItem = item;
        this.documentAdded.emit(doc);
      } else {
        this.showErrorMessage(item);
      }
    } else {
      this.showErrorMessage(item);
    }
  // tslint:disable-next-line:semicolon
  };

  showErrorMessage(item) {
    let errmsg = 'unable to upload a document';
    if (item && item._file && item.file.name) {
      errmsg = 'unable to upload document: ' + item.file.name;
    }
    if (item._xhr.status === 500) {
      const error = JSON.parse(item._xhr.response);
      if (error.ExceptionMessage) {
        errmsg += ' ' + error.ExceptionMessage;
      }
    }
    const sb = this.snackBar.openFromComponent(SnackBarErrorComponent, {
      data: errmsg,
    });
    const dismiss = sb.instance.dismiss.subscribe(x => {
      sb.dismiss();
    });
  }

  onProgressItem = item => {
    this.changeDetector.detectChanges();
  // tslint:disable-next-line:semicolon
  };

  beforeUploadItem = () => {};

  triggerUpload(event: MouseEvent) {
		if (event) {
			this.fileSelectorInput.nativeElement.click();
		}
	}
}
