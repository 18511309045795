import { Component, OnInit, Inject } from '@angular/core';
import {
  DocumentInfoDto,
  DataService,
  SaveHomepageSectionRequest,
  HomepageSectionToDocumentDto,
  HomepageSectionsDto,
  HomepageSectionToDocument,
} from '../../models/generated';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CompassForm, CompassAutoCompleteControl } from 'compass-form';

@Component({
  selector: 'edit-homepage-document-modal',
  templateUrl: './edit-homepage-document-modal.component.html',
  styleUrls: ['./edit-homepage-document-modal.component.scss'],
})
export class EditHomepageDocumentModalComponent implements OnInit {
  modalTitle: string;
  currentHomepageSection: HomepageSectionsDto;
  homepageSectionDocument: HomepageSectionToDocumentDto;
  homepageSectionDocuments: HomepageSectionToDocumentDto[] = [];
  document: DocumentInfoDto;
  documents: DocumentInfoDto[] = [];
  filteredDocuments: DocumentInfoDto[] = [];
  selected: DocumentInfoDto;
  compassForm: CompassForm<Partial<DocumentInfoDto>>;
  static open(matDialog: MatDialog, homepageSectionDocument: HomepageSectionToDocumentDto): Promise<any> {
    return matDialog
      .open(EditHomepageDocumentModalComponent, {
        width: '800px',
        data: homepageSectionDocument,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<EditHomepageDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: HomepageSectionToDocumentDto,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    this.homepageSectionDocument = this.data;
    this.compassForm = new CompassForm<Partial<HomepageSectionToDocumentDto>>({
      Id: new CompassAutoCompleteControl({
        label: 'Select Document',
        initialLabel: this.homepageSectionDocument ? this.homepageSectionDocument.Name : null,
        initialValue: this.homepageSectionDocument ? this.homepageSectionDocument.Id : null,
        optionsProvider: this.documentsQuery,
      }),
    });
    await this.getDocuments();
    this.setModalTitle();
  }

  documentsQuery = async (q: string) => {
    if (!q) {
      this.assignDocumentsCopy();
      return this.filteredDocuments.map(x => ({
        label: x.Name,
        value: x.Id,
      }));
    }
    return Object.assign([], this.documents)
      .filter(item => item.Name.toLowerCase().indexOf(q.toLowerCase()) > -1)
      .map(x => ({
        label: x.Name,
        value: x.Id,
      }));
    // tslint:disable-next-line:semicolon
  };

  setModalTitle() {
    if (this.homepageSectionDocument.DocumentId) {
      return (this.modalTitle = 'Edit Document');
    }
    return (this.modalTitle = 'Add Document');
  }

  assignDocumentsCopy() {
    this.filteredDocuments = Object.assign([], this.documents);
  }

  async getDocuments() {
    await this.dataService.homepageSection
      .getDocumentsForHomepageSections(this.homepageSectionDocument.HomepageSectionId)
      .subscribe(results => {
        this.documents = results.Documents;
        this.homepageSectionDocuments = results.HomepageSectionDocuments;
        this.currentHomepageSection = results.CurrentHomepage;
        // set document from list of documents
        this.document = this.documents.find(x => x.Id === this.homepageSectionDocument.DocumentId);
        // copy documentsList for loop
        const newDocumentsList = [...this.documents];
        // if document exists in currentHompageSection AND is not current document, remove it from list
        newDocumentsList.forEach(d => {
          // continue if document is not current document
          if (!this.document || d.Id !== this.document.Id) {
            this.homepageSectionDocuments.forEach(h => {
              if (d.Id === h.DocumentId) {
                const index = this.documents.indexOf(d);
                this.documents.splice(index, 1);
              }
            });
          }
        });
        if (this.document && this.document.Id) {
          this.selected = this.documents.find(x => x.Id === this.document.Id);
        }
        this.documents.filter(x => x.Id !== this.homepageSectionDocument.DocumentId);
        this.assignDocumentsCopy();
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  saveDocument() {
    const saveDocument = new SaveHomepageSectionRequest();
    saveDocument.Id = this.homepageSectionDocument.HomepageSectionId;
    saveDocument.Title = this.currentHomepageSection.Title;
    saveDocument.Documents = <any>this.currentHomepageSection.Documents;
    const newHomepageDoc = this.documents.find(x => x.Id === this.compassForm.value.Id);
    const docToSave = new HomepageSectionToDocument();
    docToSave.DocumentId = newHomepageDoc.Id;
    docToSave.HomepageSectionId = this.homepageSectionDocument.HomepageSectionId;

    saveDocument.Documents.push(docToSave);
    this.dataService.homepageSection.saveHomepageSection(saveDocument).subscribe(result => {
      this.onClose();
      this.snackBar.open('The document was saved!', '', {
        duration: 1500,
      });
    });
  }
}
