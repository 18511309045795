import * as moment from 'moment';
import { MyUploadsResultTag } from './MyUploadsResultTag';

export class MyUploadsResult {
	public AllTags: MyUploadsResultTag[]  = [];
	public CreatedDate: moment.Moment ;
	public Deleted: boolean ;
	public Description: string ;
	public Id: number ;
	public ItemType: string ;
	public Lifecycles: MyUploadsResultTag[]  = [];
	public Name: string ;
	public PreviewPath: string ;
	public Roles: MyUploadsResultTag[]  = [];
	public StatusTypeCode: string ;
	public Tags: MyUploadsResultTag[]  = [];
	public URL: string ;

	public constructor(
		fields?: Partial<MyUploadsResult>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.AllTags) { fields.AllTags = fields.AllTags.map(x => new MyUploadsResultTag(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new MyUploadsResultTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new MyUploadsResultTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new MyUploadsResultTag(x)); }
			Object.assign(this, fields);
		}
	}
}
