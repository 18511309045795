
export class RestoreDocumentVersionRequest {
	public CurrentUserId: number ;
	public DocumentId: number ;
	public DocumentVersionId: number ;

	public constructor(
		fields?: Partial<RestoreDocumentVersionRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
