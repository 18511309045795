import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { AccessLevelDto, DataService, SaveAccessLevelRequest } from '../../models/generated';
import { CompassForm, CompassStringControl, CompassCheckboxControl } from 'compass-form';

@Component({
  selector: 'edit-access-level-modal',
  templateUrl: './edit-access-level-modal.component.html',
  styleUrls: ['./edit-access-level-modal.component.scss'],
})
export class EditAccessLevelModalComponent implements OnInit {
  accessLevel: AccessLevelDto;
  accessLevelForm: CompassForm<Partial<AccessLevelDto>>;
  modalTitle = 'Create Access Level';

  static open(matDialog: MatDialog, accessLevel: AccessLevelDto): Promise<any> {
    return matDialog
      .open(EditAccessLevelModalComponent, {
        width: '800px',
        data: accessLevel,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<EditAccessLevelModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.accessLevel = this.data;
    this.modalTitle = this.getModalTitle(this.accessLevel);
    this.accessLevelForm = this.getAccessLevelForm();
  }
  getModalTitle(accessLevel) {
    if (accessLevel.Code) {
      return 'Edit Access Level';
    } else {
      return 'Create Access Level';
    }
  }
  getAccessLevelForm() {
    return new CompassForm<Partial<AccessLevelDto>>({
      Name: new CompassStringControl({
        label: 'Name',
        initialValue: this.accessLevel.Name,
        required: true,
        width: '50%',
      }),
      Description: new CompassStringControl({
        label: 'Description',
        initialValue: this.accessLevel.Description,
        required: true,
        width: '50%',
      }),
      Enabled: new CompassCheckboxControl({
        label: 'Enabled',
        initialValue: this.accessLevel.Enabled,
        width: '100%',
      }),
    });
  }
  close(): void {
    this.dialogRef.close();
  }
  SaveAccessLevel() {
    const saveAccessLevel = new SaveAccessLevelRequest(Object.assign({}, this.accessLevel, this.accessLevelForm.value));
    this.dataService.accessLevel.updateAccessLevel(saveAccessLevel).subscribe(result => {
      this.close();
      this.snackBar.open('The access level was saved!', '', {
        duration: 1500,
      });
    });
  }
}
