import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService, AdminLibraryTags, AdminLibraryTag, GetLibraryTagsAdminRequest } from '../../models/generated';
import { LibraryTags } from '../../models/generated/LibraryTags';
import { MatTableDataSource, MatPaginator, MatDialog, MatTable, PageEvent } from '@angular/material';
import { SaveLifecycleRequest } from '../../models/generated/SaveLifecycleRequest';
import { LibraryTag } from '../../models/generated/LibraryTag';
import { SaveRoleRequest } from '../../models/generated/SaveRoleRequest';
import { SaveTagRequest } from '../../models/generated/SaveTagRequest';
import { FormControl } from '@angular/forms';
import { AddEditLifecycleComponent } from '../add-edit-lifecycle/add-edit-lifecycle.component';
import { AddEditRoleComponent } from '../add-edit-role/add-edit-role.component';
import { AddEditTagComponent } from '../add-edit-tag/add-edit-tag.component';

@Component({
  selector: 'app-manage-libraries',
  templateUrl: './manage-libraries.component.html',
  styleUrls: ['./manage-libraries.component.scss'],
})
export class ManageLibrariesComponent implements OnInit {
  loading = true;
  tabLoadTimes: Date[] = [];
  private libraryTags: AdminLibraryTags = new AdminLibraryTags();
  displayedColumns: string[] = ['Name', 'Active', 'Instances'];
  lifecycleData: AdminLibraryTag[];
  roleData: AdminLibraryTag[];
  tagData: AdminLibraryTag[];
  lifecycleResultsLength = 0;
  roleResultsLength = 0;
  activeFilter = 'Active';
  tagResultsLength = 0;
  lifecyclePageSize = 25;
  rolePageSize = 25;
  tagPageSize = 25;
  lifecycleIndex = 0;
  roleIndex = 0;
  tagIndex = 0;
  lifecycleTerm = '';
  roleTerm = '';
  tagTerm = '';
  searchTimeout: any;

  selected = new FormControl(0);

  @ViewChild(MatPaginator) lifecyclePaginator: MatPaginator;
  @ViewChild(MatPaginator) rolePaginator: MatPaginator;
  @ViewChild(MatPaginator) tagPaginator: MatPaginator;

  constructor(private dataService: DataService, private matDialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) {}

  ngOnInit() {
    this.loading = true;
    this.refresh(0);
  }

  refresh(timeout: number) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getKeywords();
    }, timeout);
  }

  getKeywords() {
    this.dataService.libraries
      .getLibraryTagsAdmin(
        new GetLibraryTagsAdminRequest({
          LifecycleTerm: this.lifecycleTerm,
          RoleTerm: this.roleTerm,
          TagTerm: this.tagTerm,
          StartLifecycle: this.lifecycleIndex * this.lifecyclePageSize,
          StartRole: this.roleIndex * this.rolePageSize,
          StartTag: this.tagIndex * this.tagPageSize,
          CountLifecycle: this.lifecyclePageSize,
          CountRole: this.rolePageSize,
          CountTag: this.tagPageSize,
          Status: this.activeFilter,
        })
      )
      .subscribe(results => {
        this.libraryTags = results;
        this.lifecycleData = this.libraryTags.Lifecycles;
        this.roleData = this.libraryTags.Roles;
        this.tagData = this.libraryTags.Tags;
        this.lifecycleResultsLength = results.TotalLifecycle;
        this.roleResultsLength = results.TotalRole;
        this.tagResultsLength = results.TotalTag;
        this.loading = false;
      });
  }

  async addLifecycle() {
    const libraryTag = new SaveLifecycleRequest();
    await AddEditLifecycleComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  async addRole() {
    const libraryTag = new SaveRoleRequest();
    await AddEditRoleComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  async addTag() {
    const libraryTag = new SaveTagRequest();
    await AddEditTagComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  async editLifecycle(library: AdminLibraryTag) {
    const libraryTag = new SaveLifecycleRequest({
      Id: library.Id,
      Name: library.Name,
      Enabled: library.Enabled,
      Description: library.Description,
    });
    await AddEditLifecycleComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  async editRole(library: AdminLibraryTag) {
    const libraryTag = new SaveRoleRequest({ Id: library.Id, Name: library.Name, Enabled: library.Enabled });
    await AddEditRoleComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  async editTag(library: AdminLibraryTag) {
    const libraryTag = new SaveTagRequest({ Id: library.Id, Name: library.Name, Enabled: library.Enabled });
    await AddEditTagComponent.open(this.matDialog, libraryTag);
    this.refresh(0);
  }

  setPagingLifecycle(pagingEvent: PageEvent) {
    this.lifecyclePageSize = pagingEvent.pageSize;
    this.lifecycleIndex = pagingEvent.pageIndex;
    this.refresh(0);
  }

  setPagingRole(pagingEvent: PageEvent) {
    this.rolePageSize = pagingEvent.pageSize;
    this.roleIndex = pagingEvent.pageIndex;
    this.refresh(0);
  }

  setPagingTag(pagingEvent: PageEvent) {
    this.tagPageSize = pagingEvent.pageSize;
    this.tagIndex = pagingEvent.pageIndex;
    this.refresh(0);
  }

  applyLifecycleFilter(newTerm: string) {
    this.lifecycleIndex = 0;
    this.lifecycleTerm = newTerm;
    this.refresh(400);
  }

  applyRoleFilter(newTerm: string) {
    this.roleIndex = 0;
    this.roleTerm = newTerm;
    this.refresh(400);
  }

  applyTagFilter(newTerm: string) {
    this.tagIndex = 0;
    this.tagTerm = newTerm;
    this.refresh(400);
  }
  applyEnabledFilter() {
    this.refresh(0);
  }
}
