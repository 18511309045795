export class FileObjectDto {
  public Id: number;
  public Name: string;
  public IsNavigator: boolean;
  public DocumentVersionId: number;
  public constructor(fields?: Partial<FileObjectDto>) {
    this.IsNavigator = false;
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
