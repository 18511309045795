import { UserDto } from './UserDto';

export class UserListDto {
	public Start: number ;
	public Total: number ;
	public UserDtos: UserDto[]  = [];

	public constructor(
		fields?: Partial<UserListDto>) {

		if (fields) {


			if (fields.UserDtos) { fields.UserDtos = fields.UserDtos.map(x => new UserDto(x)); }
			Object.assign(this, fields);
		}
	}
}
