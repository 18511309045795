import { DocumentToAccessLevelDto } from './DocumentToAccessLevelDto';
import { DocumentToLifecycleDto } from './DocumentToLifecycleDto';
import { DocumentToRoleDto } from './DocumentToRoleDto';
import { DocumentToTagDto } from './DocumentToTagDto';
import { DocumentVersionDto } from './DocumentVersionDto';

export class EditDocumentDto {
	public AccessLevels: DocumentToAccessLevelDto[]  = [];
	public CurrentVersion: number ;
	public Deleted: boolean ;
	public Description: string ;
	public Id: number ;
	public Lifecycles: DocumentToLifecycleDto[]  = [];
	public Name: string ;
	public Roles: DocumentToRoleDto[]  = [];
	public Tags: DocumentToTagDto[]  = [];
	public Title: string ;
	public Versions: DocumentVersionDto[]  = [];

	public constructor(
		fields?: Partial<EditDocumentDto>) {

		if (fields) {


			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new DocumentToAccessLevelDto(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new DocumentToLifecycleDto(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new DocumentToRoleDto(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new DocumentToTagDto(x)); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionDto(x)); }
			Object.assign(this, fields);
		}
	}
}
