
export class MyUploadsResultTag {
	public Id: number ;
	public Name: string ;

	public constructor(
		fields?: Partial<MyUploadsResultTag>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
