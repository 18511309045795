import { Lifecycle } from './Lifecycle';
import { Role } from './Role';
import { Tag } from './Tag';

export class LibraryTagsDto {
	public Lifecycles: Lifecycle[]  = [];
	public Roles: Role[]  = [];
	public Tags: Tag[]  = [];

	public constructor(
		fields?: Partial<LibraryTagsDto>) {

		if (fields) {


			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new Lifecycle(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new Role(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new Tag(x)); }
			Object.assign(this, fields);
		}
	}
}
