import { Component, OnInit } from '@angular/core';
import { DataService, LifecycleGroupsDto, LifeCycleItemDto, SearchRequest, CurrentUserDto } from '../../models/generated';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { LifecycleDetailsComponent } from '../lifecycle-details/lifecycle-details.component';
import { SearchService } from '../../services/search.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-lifecycle',
  templateUrl: './lifecycle.component.html',
  styleUrls: ['./lifecycle.component.scss'],
})
export class LifecycleComponent implements OnInit {
  private Groups: LifecycleGroupsDto[] = [];
  public selectedGroup: number;
  public currentUser: CurrentUserDto;
  loading = true;
  lifecyclesHaveDescriptions = false;

  constructor(
    public dataService: DataService,
    private matDialog: MatDialog,
    private searchService: SearchService,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
  }

  async ngOnInit() {
    this.setCurrentUser(this.userService.cu);
    this.getGroups();
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  async getGroups() {
    this.Groups = await this.dataService.lifeCycleGroup.getLifeCycleGroups().toPromise();
    if (this.Groups.length > 0) {
      if (this.currentUser.LifecycleGroupId && this.Groups.find(g => g.Id === this.currentUser.LifecycleGroupId)) {
        this.selectedGroup = this.currentUser.LifecycleGroupId;
      } else {
        this.selectedGroup = this.Groups[0].Id;
      }
      this.Groups.forEach(x => {
        if (x.LifeCycles.find(l => l.Description !== null)) {
          this.lifecyclesHaveDescriptions = true;
        }
      });
    }
    this.loading = false;
  }

  search(lifecycle: LifeCycleItemDto) {
    const searchRequest = new SearchRequest({ Lifecycles: [lifecycle.LifeCycleId] });
    this.searchService.search(searchRequest);
  }

  async showDetails() {
    const group = this.Groups.find(x => x.Id === this.selectedGroup);
    await LifecycleDetailsComponent.open(this.matDialog, group);
  }
}
