export function validateEmailAddress(emailAddress: string): boolean {
if (emailAddress !== null) {
// https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
	const validAddress = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // tslint:disable-line
if (validAddress.test(emailAddress)) {
return true;
} else {
return false;
}
}
return false;
}
