import * as moment from 'moment';
import { DocumentVersionTestDto } from './DocumentVersionTestDto';

export class DocumentRequestDto {
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public Description: string ;
	public DocumentStatusTypeCode: string ;
	public Id: number ;
	public Name: string ;
	public PreviewPath: string ;
	public Versions: DocumentVersionTestDto[]  = [];

	public constructor(
		fields?: Partial<DocumentRequestDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionTestDto(x)); }
			Object.assign(this, fields);
		}
	}
}
