import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './uploader';
import { FileUploadModule } from 'ng2-file-upload';
import { UploaderService } from './uploader/uploader.service';
import { MaterialModule } from './material.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AddContentButtonComponent } from './add-content-button/add-content-button.component';
import { KeyboardClickDirective } from './shared/keyboard-click.directive';

@NgModule({
  declarations: [UploaderComponent, LoadingSpinnerComponent, AddContentButtonComponent, KeyboardClickDirective],
  imports: [CommonModule, FileUploadModule, MaterialModule],
  exports: [UploaderComponent, LoadingSpinnerComponent, AddContentButtonComponent, FileUploadModule, KeyboardClickDirective],
  providers: [UploaderService],
})
export class SharedModule {}
