import { Observable } from 'rxjs';
import { DataService } from '../models/generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export function formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

@Injectable()
export class FileObjectDownloader {
  constructor(private dataService: DataService, private http: HttpClient) {}

  downloader = {
    download: (id: number): Observable<Blob> =>
      this.http.get(`${this.dataService.apiRelativePath}/Document/Download?id=${id}`, { responseType: 'blob' }),
  };

  versionDownloader = {
    downloadVersion: (id: number): Observable<Blob> =>
      this.http.get(`${this.dataService.apiRelativePath}/Document/DownloadVersion?id=${id}`, { responseType: 'blob' }),
  };

  download(fileObjectId: number, fileName: string) {
    this.downloader.download(fileObjectId).subscribe(blob => {
      const a = document.createElement('a');
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }

  downloadVersion(fileObjectId: number, fileName: string) {
    this.versionDownloader.downloadVersion(fileObjectId).subscribe(blob => {
      const a = document.createElement('a');
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
      }
    });
  }
}
