import { Component, OnInit, Inject } from '@angular/core';
import { SaveTagRequest, DataService, LibraryTag } from '../../models/generated';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'add-edit-tag',
  templateUrl: './add-edit-tag.component.html',
  styleUrls: ['./add-edit-tag.component.scss'],
})
export class AddEditTagComponent implements OnInit {
  modalTitle = '';
  selectedLibrary: SaveTagRequest;
  selectedLibraryName: string;
  tagsList: LibraryTag[] = [];
  tagExists = false;
  static open(matDialog: MatDialog, libraryTag: SaveTagRequest): Promise<any> {
    return matDialog
      .open(AddEditTagComponent, {
        width: '800px',
        data: libraryTag,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditTagComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.selectedLibrary = this.data;
    this.selectedLibraryName = this.selectedLibrary.Name;
    this.setModalTitle();
    this.setEnabledIfNew(this.selectedLibrary);
    this.getTags();
  }

  getTags() {
    this.dataService.libraries.getLibraryTags().subscribe(results => {
      this.tagsList = results.Tags;
    });
  }

  checkIfTagExists(name: string) {
    if (name.trim().toLowerCase() === this.selectedLibraryName.trim().toLowerCase()) {
      return (this.tagExists = false);
    }
    this.tagExists = this.tagsList.findIndex(l => l.Name.trim().toLowerCase() === name.trim().toLowerCase() && l.Enabled) > -1;
  }

  setModalTitle() {
    if (this.selectedLibrary.Id) {
      this.modalTitle = 'Edit Keyword';
    } else {
      this.modalTitle = 'Add Keyword';
    }
  }

  setEnabledIfNew(tag) {
    if (!tag.Id) {
      tag.Enabled = true;
    }
  }

  saveLibrary() {
    this.dataService.libraries.saveTag(this.selectedLibrary).subscribe(result => {
      this.onClose();
      this.snackBar.open('The keyword was saved!', '', {
        duration: 1500,
      });
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isDisabled() {
    return !this.selectedLibrary.Name || this.tagExists;
  }
}
