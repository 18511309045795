import { Component, OnInit, OnDestroy } from '@angular/core';
// import Quill from 'quill';
// import ImageResize from 'quill-image-resize-module';
import { FormControl } from '@angular/forms';
import { DataService, NewsDto } from '../../models/generated';
import { MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss'],
})
export class EditNewsComponent implements OnInit, OnDestroy {
  modules = {};
  selected = new FormControl(0);
  newsData: any[];
  saveTimeout: any;
  fileUploadErrorMessage: string;
  imgCounter = 0;
  loading = true;
  titleError: boolean;

  constructor(public dataService: DataService, private httpClient: HttpClient) {
    this.modules = {
      toolbar: [
        [{ header: 1 }, { header: 2 }],
        ['bold', 'italic', 'underline'],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ color: [] }, { background: [] }],
      ],
      keyboard: { bindings: { tab: false } },
    };
  }

  ngOnInit() {
    this.getNews();
  }

  async getNews() {
    await this.dataService.news.getNews().subscribe(results => {
      this.newsData = results;
      this.loading = false;
    });
  }

  uploadFile(fileInput, newsId) {
    this.imgCounter++;
    const file = fileInput.target.files[0];
    file.Id = newsId;
    const data = new FormData();
    data.append(file.name, file);
    data.append('fileId', file.Id);
    this.httpClient.post('api/News/UploadNewsImage', data).subscribe(
      result => {
        this.getNews();
      },
      err => {
        console.log(err);
      }
    );
  }

  addTab() {
    const newNews = new NewsDto();
    this.newsData.push(newNews);
    this.selected.setValue(this.newsData.length - 1);
    this.dataService.news.saveNews(newNews).subscribe(result => {
      this.getNews();
    });
  }

  addStartDate(event: MatDatepickerInputEvent<Date>, news: NewsDto) {
    news.StartDate = moment(event.value);
    this.checkTitleLength(news.Title);
    if (!this.titleError) {
      this.dataService.news.saveNews(news).subscribe();
    }
  }

  saveNews(news: NewsDto) {
    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.checkTitleLength(news.Title);
      if (!this.titleError) {
        this.dataService.news.saveNews(news).subscribe();
      }
    }, 300);
  }

  addEndDate(event: MatDatepickerInputEvent<Date>, news: NewsDto) {
    news.EndDate = moment(event.value);
    this.checkTitleLength(news.Title);
    if (!this.titleError) {
      this.dataService.news.saveNews(news).subscribe();
    }
  }

  removeTab(newsId: number, index: number) {
    if (newsId) {
      this.dataService.news.deleteNews(newsId).toPromise();
    }
    this.newsData.splice(index, 1);
  }

  deleteSavedImage(news: NewsDto) {
    news.ImagePath = null;
    this.checkTitleLength(news.Title);
    if (!this.titleError) {
      this.dataService.news.saveNews(news).subscribe();
    }
  }

  checkTitleLength(title: string) {
    if (title && title.length > 160) {
      return (this.titleError = true);
    } else if (title && title.length === 160) {
      this.titleError = true;
      return false;
    } else {
      return (this.titleError = false);
    }
  }

  ngOnDestroy() {
    this.newsData.forEach(x => {
      if (!x.StartDate && !x.EndDate && !x.Description && !x.Title) {
        this.dataService.news.deleteNews(x.Id).toPromise();
      }
    });
  }

  imgPreview(id: number) {
    return 'api/News/GetNewsImagePreview/' + id + '?' + this.imgCounter;
  }
}
