import * as moment from 'moment';
import { Document } from './Document';
import { DocumentStatusType } from './DocumentStatusType';
import { User } from './User';

export class DocumentVersion {
	public ApprovedByUser: User ;
	public ApprovedByUserId: number ;
	public ApprovedDate: moment.Moment ;
	public CreatedByUser: User ;
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public Document: Document ;
	public DocumentId: number ;
	public DocumentName: string ;
	public DocumentStatusType: DocumentStatusType ;
	public DocumentStatusTypeCode: string ;
	public FilePath: string ;
	public Id: number ;
	public PreviewPath: string ;
	public ThumbnailPath: string ;

	public constructor(
		fields?: Partial<DocumentVersion>) {

		if (fields) {
			if (fields.ApprovedByUser) { fields.ApprovedByUser = new User(fields.ApprovedByUser); }
			if (fields.CreatedByUser) { fields.CreatedByUser = new User(fields.CreatedByUser); }
			if (fields.Document) { fields.Document = new Document(fields.Document); }
			if (fields.DocumentStatusType) { fields.DocumentStatusType = new DocumentStatusType(fields.DocumentStatusType); }
			if (fields.ApprovedDate) { fields.ApprovedDate = moment(fields.ApprovedDate); }
			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }

			Object.assign(this, fields);
		}
	}
}
