import { DocumentToRole } from './DocumentToRole';
import { LinkToRole } from './LinkToRole';

export class Role {
	public Documents: DocumentToRole[]  = [];
	public Enabled: boolean ;
	public Id: number ;
	public Links: LinkToRole[]  = [];
	public Name: string ;

	public constructor(
		fields?: Partial<Role>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new DocumentToRole(x)); }
			if (fields.Links) { fields.Links = fields.Links.map(x => new LinkToRole(x)); }
			Object.assign(this, fields);
		}
	}
}
