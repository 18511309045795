import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  DataService,
  LibraryTags,
  LibraryTag,
  LifecycleGroupsDto,
  LifeCycleItemDto,
  SaveLifeCycleGroupRequest,
  LifecycleGroupToLifecycle,
  SaveLifecycleRequest,
  CurrentUserDto,
} from '../../models/generated';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AddEditLifecycleComponent } from '../add-edit-lifecycle/add-edit-lifecycle.component';
import { FileUploader } from 'ng2-file-upload';
import { MAX_FILE_SIZE } from '../../utils/constants';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-life-cycle-group',
  templateUrl: './life-cycle-group.component.html',
  styleUrls: ['./life-cycle-group.component.scss'],
})
export class LifeCycleGroupComponent implements OnInit, OnDestroy {
  public libraryTags: LibraryTags = new LibraryTags();
  public Groups: LifecycleGroupsDto[] = [];
  public selectedGroup: number;
  selected = new FormControl(0);
  public newPhase: LibraryTag = new LibraryTag();
  selectedValue: number;
  loading = true;
  currentUser: CurrentUserDto;
  lifecycleSvgUploader: FileUploader = new FileUploader({
    url: 'api/LifecycleGroup/UploadLifecycleSvg',
    autoUpload: true,
    removeAfterUpload: true,
    maxFileSize: MAX_FILE_SIZE,
    queueLimit: 1,
  });
  filePreviewPath: SafeUrl;
  fileUploadErrorMessage: string;
  constructor(
    public dataService: DataService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });

    this.lifecycleSvgUploader.onAfterAddingFile = fileItem => {
      if (!fileItem.file.type.includes('svg')) {
        this.updateErrorMessage('please only upload an svg for lifecycle groups.');
        this.removeSvgBeforeUpload();
      }
    };
  }

  async ngOnInit() {
    this.setCurrentUser(this.userService.cu);
    this.getLifecycleTags();
    this.Groups = [];
    this.getGroups();
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
    console.log(this.currentUser);
  }

  updateErrorMessage(message: string) {
    this.fileUploadErrorMessage = message;
  }

  removeSvgBeforeUpload() {
    this.lifecycleSvgUploader.queue = [];
  }

  removeSvg(group: LifecycleGroupsDto) {
    group.SvgFilePath = null;
    this.saveGroup(group);
  }

  async getLifecycleTags() {
    await this.dataService.libraries.getLibraryTags().subscribe(results => {
      this.libraryTags = results;
      this.loading = false;
    });
  }

  async getGroups(newId: number = 0) {
    this.Groups = await this.dataService.lifeCycleGroup.getLifeCycleGroups().toPromise();
    if (newId === 0) {
      if (this.Groups.length > 0) {
        this.selectedGroup = this.Groups[0].Id;
        if (!this.Groups[0].SvgFilePath) {
        }
      }
    } else {
      this.selectedGroup = newId;
    }
  }

  addedPhase(group: LifecycleGroupsDto) {
    const item = new LifeCycleItemDto();
    item.LifeCycleId = this.newPhase.Id;
    item.Name = this.newPhase.Name;
    item.Ordinal = 1;
    group.LifeCycles.push(item);
    this.newPhase = null;
    this.saveGroup(group);
  }

  async saveGroup(group: LifecycleGroupsDto) {
    const newRequest = new SaveLifeCycleGroupRequest();
    group.LifeCycles.forEach(lci => {
      const ltgl = new LifecycleGroupToLifecycle();
      ltgl.Id = lci.Id;
      ltgl.LifecycleGroupId = lci.LifeCycleGroupId;
      ltgl.LifecycleId = lci.LifeCycleId;
      newRequest.LifeCycles.push(ltgl);
    });
    newRequest.Id = group.Id;
    newRequest.Name = group.Name;
    newRequest.Ordinal = group.Ordinal;
    newRequest.SvgForLifecycle = group.SvgForLifecycle;
    await this.dataService.lifeCycleGroup
      .saveGroup(newRequest)
      .toPromise()
      .then(function(result) {
        group.Id = result;
      });
    // this.getGroups();
  }

  async docAdded(doc, id: number) {
    const lifecycleGroup = new SaveLifeCycleGroupRequest((<any>doc).uploaderItem);
    await this.getGroups();
    const group = this.Groups.find(x => x.Id === id);
    const groupToDelete = this.Groups.find(x => x.Id === doc.Id);
    lifecycleGroup.Id = group.Id;
    group.LifeCycles.forEach(lci => {
      const ltgl = new LifecycleGroupToLifecycle();
      ltgl.Id = lci.Id;
      ltgl.LifecycleGroupId = lci.LifeCycleGroupId;
      ltgl.LifecycleId = lci.LifeCycleId;
      lifecycleGroup.LifeCycles.push(ltgl);
    });
    lifecycleGroup.Name = group.Name;
    lifecycleGroup.Ordinal = group.Ordinal;
    lifecycleGroup.SvgForLifecycle = group.SvgForLifecycle;
    lifecycleGroup.SvgFilePath = groupToDelete.SvgFilePath;
    await this.dataService.lifeCycleGroup.saveGroup(lifecycleGroup).subscribe(result => {
      group.Id = result;
      this.dataService.lifeCycleGroup.deleteLifecycleGroup(groupToDelete.Id).subscribe();
      this.getGroups();
    });
  }

  removePhase(group: LifecycleGroupsDto, id: number) {
    const index = group.LifeCycles.map(x => x.LifeCycleId).indexOf(id);
    group.LifeCycles.splice(index, 1);
    this.snackBar.open('Phase Removed', '', {
      duration: 1500,
    });
    this.saveGroup(group);
  }

  async deleteGroup(id: number) {
    await this.dataService.lifeCycleGroup.deleteLifecycleGroup(id).toPromise();
    this.snackBar.open('Group Deleted', '', {
      duration: 1500,
    });
    this.getGroups();
  }

  addGroup() {
    const newGroup = new LifecycleGroupsDto();
    newGroup.Id = 0;
    newGroup.Name = 'New Group';
    this.Groups.push(newGroup);
    this.selected.setValue(this.Groups.length - 1);
    this.selectedGroup = 0;
    this.saveGroup(newGroup);
  }

  getLifecycles(group: LifecycleGroupsDto, lifecycleId: number): LibraryTag[] {
    if (!group.LifeCycles) {
      return this.libraryTags.Lifecycles;
    }
    return this.libraryTags.Lifecycles.filter(
      l => group.LifeCycles.findIndex(x => x.LifeCycleId === l.Id) < 0 || (lifecycleId && l.Id === lifecycleId)
    );
  }

  async openAddLifecycle() {
    const newLifecycleTag = new SaveLifecycleRequest();
    await AddEditLifecycleComponent.open(this.matDialog, newLifecycleTag);
    this.getLifecycleTags();
  }

  ngOnDestroy() {
    this.Groups.forEach(x => {
      if (x.LifeCycles.length === 0 && (!x.Name || x.Name === 'New Group') && !x.SvgFilePath) {
        this.dataService.lifeCycleGroup.deleteLifecycleGroup(x.Id).toPromise();
      }
    });
  }
}
