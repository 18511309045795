import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UserDto, SaveUserRequest, DataService, PermissionDto, AccessLevelDto, AccessLevel } from '../../models/generated';
import { CompassForm, CompassStringControl, CompassCheckboxControl } from 'compass-form';
import { ApValidators } from '../../utils/validators';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit {
  user: UserDto;
  permissions: PermissionDto[];
  accessLevels: AccessLevelDto[];
  userForm: CompassForm<Partial<UserDto>>;
  modalTitle = 'Create User';

  static open(matDialog: MatDialog, user: UserDto): Promise<any> {
    return matDialog
      .open(AddEditUserComponent, {
        width: '800px',
        data: user,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: UserDto,
    private snackBar: MatSnackBar,
    public dataService: DataService
  ) {}

  async ngOnInit() {
    this.user = this.data;
    this.modalTitle = this.getModalTitle(this.user);
    this.getAllPermissions();
    this.getAccessLevels();
    this.setEnabledIfNewUser(this.user);
    this.userForm = this.getUserForm();
  }

  setEnabledIfNewUser(user) {
    if (!user.Id) {
      user.Enabled = true;
    }
  }
  getModalTitle(user) {
    if (user.Id) {
      return 'Edit User';
    } else {
      return 'Create User';
    }
  }

  getUserForm() {
    return new CompassForm<Partial<UserDto>>({
      FirstName: new CompassStringControl({
        label: 'First Name',
        initialValue: this.user.FirstName,
        required: true,
        width: '50%',
      }),
      LastName: new CompassStringControl({
        label: 'Last Name',
        initialValue: this.user.LastName,
        required: true,
        width: '50%',
      }),
      UserName: new CompassStringControl({
        label: 'UserName',
        initialValue: this.user.UserName,
        required: true,
        width: '50%',
      }),
      Email: new CompassStringControl({
        label: 'Email',
        initialValue: this.user.Email,
        required: false,
        width: '50%',
        validators: [ApValidators.IsEmail],
      }),
      Enabled: new CompassCheckboxControl({
        label: 'Enabled',
        initialValue: this.user.Enabled,
        width: '100%',
      }),
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  getAllPermissions() {
    this.dataService.user.getAllPermissions().subscribe(permissions => {
      this.permissions = permissions;
      return this.permissions;
    });
  }

  getAccessLevels() {
    this.dataService.accessLevel.getAccessLevels().subscribe(levels => {
      this.accessLevels = levels.filter(el => el.Enabled === true);
      return this.accessLevels;
    });
  }

  createOrUpdateUser(newUser: SaveUserRequest) {
    const saveNewUser = new SaveUserRequest(Object.assign({}, newUser, this.userForm.value));
    const newUserPermissions: any = saveNewUser.Permissions;
    saveNewUser.Permissions = [];
    newUserPermissions.forEach(p => saveNewUser.Permissions.push(p.Code));

    const newUserAccessLevels: any = saveNewUser.AccessLevels;
    saveNewUser.AccessLevels = [];
    newUserAccessLevels.forEach(p => saveNewUser.AccessLevels.push(p.Id));

    this.dataService.user.saveUser(saveNewUser).subscribe(u => {
      this.dialogRef.close(saveNewUser);
      this.snackBar.open('User has been updated', '', {
        duration: 1500,
      });
    });
  }

  onPermissionsChanged(p: PermissionDto) {
    const index = this.user.Permissions.map(x => x.Code).indexOf(p.Code);
    if (index > -1) {
      this.user.Permissions.splice(index, 1);
    } else {
      this.user.Permissions.push(p);
    }
  }

  invalidUser() {
    return !this.userForm || !this.userForm.valid;
  }

  isPermissionChecked(p: PermissionDto) {
    return this.user.Permissions.findIndex(x => x.Code === p.Code) >= 0;
  }

  onAccessLevelChanged(p: AccessLevelDto) {
    const index = this.user.AccessLevels.map(x => x.Id).indexOf(p.Id);
    if (index > -1) {
      this.user.AccessLevels.splice(index, 1);
    } else {
      this.user.AccessLevels.push(p);
    }
  }
  isAccessLevelChecked(p: AccessLevelDto) {
    return this.user.AccessLevels.findIndex(x => x.Id === p.Id) >= 0;
  }
}
