import * as moment from 'moment';
import { DocToLifecycleTestDto } from './DocToLifecycleTestDto';
import { DocToRoleTestDto } from './DocToRoleTestDto';
import { DocToTagTestDto } from './DocToTagTestDto';
import { DocumentVersionTestDto } from './DocumentVersionTestDto';

export class DocumentTestDto {
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public Description: string ;
	public DocumentStatusTypeCode: string ;
	public Id: number ;
	public Lifecycles: DocToLifecycleTestDto[]  = [];
	public Name: string ;
	public Roles: DocToRoleTestDto[]  = [];
	public Tags: DocToTagTestDto[]  = [];
	public Versions: DocumentVersionTestDto[]  = [];

	public constructor(
		fields?: Partial<DocumentTestDto>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new DocToLifecycleTestDto(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new DocToRoleTestDto(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new DocToTagTestDto(x)); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionTestDto(x)); }
			Object.assign(this, fields);
		}
	}
}
