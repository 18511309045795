
export class ToolkitDto {
	public Id: number ;
	public ImagePath: string ;
	public LinkId: number ;
	public Title: string ;
	public Url: string ;

	public constructor(
		fields?: Partial<ToolkitDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
