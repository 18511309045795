import { MyUploadsResult } from './MyUploadsResult';

export class MyUploadsResultList {
	public Results: MyUploadsResult[]  = [];

	public constructor(
		fields?: Partial<MyUploadsResultList>) {

		if (fields) {


			if (fields.Results) { fields.Results = fields.Results.map(x => new MyUploadsResult(x)); }
			Object.assign(this, fields);
		}
	}
}
