import { HomepageSectionToDocument } from './HomepageSectionToDocument';

export class HomepageSection {
	public Documents: HomepageSectionToDocument[]  = [];
	public Id: number ;
	public Ordinal: number ;
	public Title: string ;

	public constructor(
		fields?: Partial<HomepageSection>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new HomepageSectionToDocument(x)); }
			Object.assign(this, fields);
		}
	}
}
