import { Document } from './Document';
import { Tag } from './Tag';

export class DocumentToTag {
	public Document: Document ;
	public DocumentId: number ;
	public Id: number ;
	public Tag: Tag ;
	public TagId: number ;

	public constructor(
		fields?: Partial<DocumentToTag>) {

		if (fields) {
			if (fields.Document) { fields.Document = new Document(fields.Document); }
			if (fields.Tag) { fields.Tag = new Tag(fields.Tag); }


			Object.assign(this, fields);
		}
	}
}
