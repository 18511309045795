import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

import {
  DataService,
  UpdateApprovalItemRequest,
  SaveLinkRequest,
  LinkToLifecycleDto,
  EditDocumentDto,
  ApprovalItemDto,
  DocumentStatusType,
} from '../../models/generated';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { MatDialog } from '@angular/material';
import { FileObjectDto } from '../../models/FileObjectDto';
import { FileObjectDownloader } from '../../home/fileObjectHelper';
import { AddLinkComponent } from '../../home/add-link/add-link.component';
import { EditDocumentComponent } from '../../home/edit-document/edit-document.component';
import { ItemViewService } from '../../services/itemView.service';

@Component({
  selector: 'app-approve-content',
  templateUrl: './approve-content.component.html',
  styleUrls: ['./approve-content.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(
          ':enter',
          stagger('300ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateY(35px)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
        query(
          ':leave',
          stagger('300ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateX(35px)', offset: 0.3 }),
                style({ opacity: 0, transform: 'translateX(-5%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ApproveContentComponent implements OnInit {
  private approvalItems: ApprovalItemDto[] = [];
  loading = true;
  constructor(
    private matDialog: MatDialog,
    public dataService: DataService,
    public downloader: FileObjectDownloader,
    private itemViewService: ItemViewService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  async refresh() {
    this.approvalItems = await this.dataService.approvals.getItemsForApproval().toPromise();
    console.log(this.approvalItems);
    this.loading = false;
  }

  async UpdateStatus(item: ApprovalItemDto) {
    const update = new UpdateApprovalItemRequest();
    update.ItemId = item.Id;
    update.ItemType = item.ItemType;
    update.ItemName = item.ItemName;
    update.Status = item.ItemStatus;
    update.UploaderId = item.UploaderId;
    update.comment = item.Comment;
    await this.dataService.approvals.updateApprovalItem(update).toPromise();
    const index = this.approvalItems.indexOf(item);
    this.approvalItems.splice(index, 1);
    this.itemViewService.refresh(); // Don't await the promise because this can be done without blocking
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  async previewDocument(item: ApprovalItemDto) {
    const pendingVersion = item.Versions.find(v => v.DocumentStatusTypeCode === DocumentStatusType.Pending);
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.ItemName, DocumentVersionId: pendingVersion.Id });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  editLink(linkId: number) {
    const link = new SaveLinkRequest();
    this.dataService.link
      .getEditLink(linkId)
      .toPromise()
      .then(result => {
        link.Id = result.Id;
        link.Description = result.Description;
        link.Name = result.Name;
        link.Roles = result.Roles;
        link.Tags = result.Tags;
        link.Lifecycles = result.Lifecycles;
        link.AccessLevels = result.AccessLevels;
        link.URL = result.URL;
        link.Deleted = result.Deleted;
        AddLinkComponent.open(this.matDialog, link).finally(() => {
          this.refresh();
        });
      });
  }

  async openEditDocumentModal(documentId: number) {
    let document = new EditDocumentDto();
    await this.dataService.document.getEditDocument(documentId).subscribe(result => {
      document = result;
      EditDocumentComponent.open(this.matDialog, document).finally(() => {
        this.refresh();
      });
    });
  }

  async download(item: ApprovalItemDto) {
    this.downloader.download(item.Id, item.ItemName);
  }
}
