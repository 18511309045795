import { DocumentInfoDto } from './DocumentInfoDto';
import { HomepageSectionsDto } from './HomepageSectionsDto';
import { HomepageSectionToDocumentDto } from './HomepageSectionToDocumentDto';

export class HomepageDocumentResultList {
	public CurrentHomepage: HomepageSectionsDto ;
	public Documents: DocumentInfoDto[]  = [];
	public HomepageSectionDocuments: HomepageSectionToDocumentDto[]  = [];

	public constructor(
		fields?: Partial<HomepageDocumentResultList>) {

		if (fields) {
			if (fields.CurrentHomepage) { fields.CurrentHomepage = new HomepageSectionsDto(fields.CurrentHomepage); }

			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new DocumentInfoDto(x)); }
			if (fields.HomepageSectionDocuments) { fields.HomepageSectionDocuments = fields.HomepageSectionDocuments.map(x => new HomepageSectionToDocumentDto(x)); }
			Object.assign(this, fields);
		}
	}
}
