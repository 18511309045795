
export class DocumentToTagDto {
	public DocumentId: number ;
	public Id: number ;
	public Name: string ;
	public TagId: number ;

	public constructor(
		fields?: Partial<DocumentToTagDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
