
export class CurrentUserDto {
	public CanSeeAdminDropdown: boolean ;
	public Email: string ;
	public FirstName: string ;
	public Id: number ;
	public IsContentAdmin: boolean ;
	public IsSiteAdmin: boolean ;
	public IsUploader: boolean ;
	public LastName: string ;
	public LifecycleGroupId: number ;
	public UserName: string ;

	public constructor(
		fields?: Partial<CurrentUserDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
