import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DataService } from '../../models/generated';
import { WhitelistTag } from '../../models/generated';
import { SaveWhitelistExtensionRequest } from '../../models/generated/SaveWhitelistExtensionRequest';
import { ApplicationValue } from '../../models/generated/ApplicationValue';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  whitelistTags: WhitelistTag[] = [];
  appSettings: ApplicationValue[] = [];
  contentApproval: string = ApplicationValue.CONTENT_APPROVAL;
  contentApprovalBool: boolean;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.getWhitelistTags();
    this.getAppSettings();
  }

  async getWhitelistTags() {
    this.whitelistTags = await this.dataService.settings.getWhitelistTags().toPromise();
  }

  async getAppSettings() {
    this.appSettings = await this.dataService.applicationValue.getAllValues().toPromise();
    this.contentApprovalBool = this.appSettings.find(x => x.Id === this.contentApproval)
      ? this.appSettings.find(x => x.Id === this.contentApproval).Value === 'true'
      : false;
  }

  async saveSettings(appSettings: ApplicationValue[]) {
    appSettings.find(x => x.Id === this.contentApproval).Value = this.contentApprovalBool.toString();
    await this.dataService.applicationValue.setValues(appSettings).toPromise();
  }

  async add(event: MatChipInputEvent) {
    console.log(event);
    const input = event.input;
    const value = new SaveWhitelistExtensionRequest({ Extension: event.value });

    // trim extension so it does not add whitespace to tag
    if ((value.Extension || '').trim()) {
      const id = await this.dataService.settings.saveWhitelistExtension(value).toPromise();
      this.whitelistTags.push(new WhitelistTag({ Id: id, Extension: event.value.trim() }));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  async delete(ext: WhitelistTag) {
    await this.dataService.settings.deleteWhitelistExtension(ext.Id).toPromise();
    const index = this.whitelistTags.indexOf(ext);
    this.whitelistTags.splice(index, 1);
  }
}
