import { AccessLevel } from './AccessLevel';
import { Document } from './Document';

export class DocumentToAccessLevel {
	public AccessLevel: AccessLevel ;
	public AccessLevelId: number ;
	public Document: Document ;
	public DocumentId: number ;
	public Id: number ;

	public constructor(
		fields?: Partial<DocumentToAccessLevel>) {

		if (fields) {
			if (fields.AccessLevel) { fields.AccessLevel = new AccessLevel(fields.AccessLevel); }
			if (fields.Document) { fields.Document = new Document(fields.Document); }


			Object.assign(this, fields);
		}
	}
}
