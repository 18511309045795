import { HomepageSectionToDocumentDto } from './HomepageSectionToDocumentDto';

export class HomepageSectionsDto {
	public Documents: HomepageSectionToDocumentDto[]  = [];
	public Id: number ;
	public Ordinal: number ;
	public Title: string ;

	public constructor(
		fields?: Partial<HomepageSectionsDto>) {

		if (fields) {


			if (fields.Documents) { fields.Documents = fields.Documents.map(x => new HomepageSectionToDocumentDto(x)); }
			Object.assign(this, fields);
		}
	}
}
