import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NewsDto } from '../../models/generated';

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss'],
})
export class NewsModalComponent implements OnInit {
  news: NewsDto;
  static open(dialog: MatDialog, news: NewsDto): Promise<any> {
    return dialog
      .open(NewsModalComponent, {
        width: '1000px',
        data: news,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(private dialogRef: MatDialogRef<NewsModalComponent>, @Inject(MAT_DIALOG_DATA) private data: NewsDto) {}

  ngOnInit() {
    this.news = this.data;
  }

  close(): void {
    this.dialogRef.close();
  }
}
