import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService, SaveItemViewRequest, QuickLink, QuickLinksDto } from '../models/generated';
import { Subject } from 'rxjs';

@Injectable()
export class ItemViewService {
  private quickLinksSource = new Subject<QuickLinksDto>();
  quickLinks$ = this.quickLinksSource.asObservable();
  public ql: QuickLinksDto;

  constructor(private dataService: DataService) {
    this.refresh();
  }

  async refresh() {
    this.ql = await this.dataService.quickLinks.getQuickLinks().toPromise();
    this.quickLinksSource.next(this.ql);
  }

  async saveItemView(request: SaveItemViewRequest) {
    await this.dataService.quickLinks.saveItemView(request).toPromise();
    this.refresh();
  }
}
