import * as moment from 'moment';
import { DocumentVersionDto } from './DocumentVersionDto';
import { SearchResultTag } from './SearchResultTag';

export class SearchResult {
	public AllTags: SearchResultTag[]  = [];
	public CreatedDate: moment.Moment ;
	public CurrentVersionId: number ;
	public Deleted: boolean ;
	public Description: string ;
	public Id: number ;
	public ItemType: string ;
	public Lifecycles: SearchResultTag[]  = [];
	public Name: string ;
	public PreviewPath: string ;
	public Roles: SearchResultTag[]  = [];
	public Tags: SearchResultTag[]  = [];
	public URL: string ;
	public Versions: DocumentVersionDto[]  = [];

	public constructor(
		fields?: Partial<SearchResult>) {

		if (fields) {

			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.AllTags) { fields.AllTags = fields.AllTags.map(x => new SearchResultTag(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new SearchResultTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new SearchResultTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new SearchResultTag(x)); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionDto(x)); }
			Object.assign(this, fields);
		}
	}
}
