import { LinkToAccessLevelDto } from './LinkToAccessLevelDto';
import { LinkToLifecycleDto } from './LinkToLifecycleDto';
import { LinkToRoleDto } from './LinkToRoleDto';
import { LinkToTagDto } from './LinkToTagDto';

export class SaveLinkRequest {
	public AccessLevels: LinkToAccessLevelDto[]  = [];
	public Deleted: boolean ;
	public Description: string ;
	public Id: number ;
	public Lifecycles: LinkToLifecycleDto[]  = [];
	public Name: string ;
	public Roles: LinkToRoleDto[]  = [];
	public Tags: LinkToTagDto[]  = [];
	public URL: string ;
	public UserId: number ;

	public constructor(
		fields?: Partial<SaveLinkRequest>) {

		if (fields) {


			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new LinkToAccessLevelDto(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new LinkToLifecycleDto(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new LinkToRoleDto(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new LinkToTagDto(x)); }
			Object.assign(this, fields);
		}
	}
}
