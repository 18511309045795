import { Component, OnInit } from '@angular/core';
import {
  DocumentInfoDto,
  DataService,
  SaveItemViewRequest,
  ItemViewLocation,
  HomepageSectionsDto,
  HomepageSectionToDocumentDto,
} from '../../models/generated';
import { FileObjectDto } from '../../models/FileObjectDto';
import { PreviewDocumentComponent } from '../../admin/preview-document/preview-document.component';
import { MatDialog } from '@angular/material';
import { ItemViewService } from '../../services/itemView.service';

@Component({
  selector: 'app-custom-sections',
  templateUrl: './custom-sections.component.html',
  styleUrls: ['./custom-sections.component.scss'],
})
export class CustomSectionsComponent implements OnInit {
  homepageSections: HomepageSectionsDto[] = [];
  constructor(private dataService: DataService, public matDialog: MatDialog, private itemViewService: ItemViewService) {}

  async ngOnInit() {
    this.homepageSections = await this.dataService.homepageSection.getHomepageSections().toPromise();
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  showThumbnailImage(documentId: number) {
    const element = document.getElementById('thumbnail-image-' + documentId);
    element.parentNode.removeChild(element);
    const newElement = document.createElement('div');
    newElement.style.cssText = `width: 150px;
      height: 200px;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      font-size: 16px;
      font-weight: bold;
      background-color: #f2f2f2;
      text-shadow: 1px 1px #b3b3b3;
      border-radius: 5px;`;
    const newContent = document.createTextNode('No Preview Available');
    newElement.appendChild(newContent);

    const currentDiv = document.getElementById('image-' + documentId);
    currentDiv.appendChild(newElement);
  }

  async previewDocument(item: HomepageSectionToDocumentDto) {
    this.itemViewService.saveItemView(
      new SaveItemViewRequest({ DocumentId: item.DocumentId, Location: ItemViewLocation.AcquisitionNavigator })
    );
    const document = await this.dataService.document.getDocumentInfo(item.DocumentId).toPromise();
    const fileObject = new FileObjectDto({
      Id: item.DocumentId,
      Name: document.DocumentName,
      IsNavigator: false,
      DocumentVersionId: document.DocumentVersionId,
    });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }
}
