import { AdminLibraryTag } from './AdminLibraryTag';

export class AdminLibraryTags {
	public Lifecycles: AdminLibraryTag[]  = [];
	public Roles: AdminLibraryTag[]  = [];
	public StartLifecycle: number ;
	public StartRole: number ;
	public StartTag: number ;
	public Tags: AdminLibraryTag[]  = [];
	public TotalLifecycle: number ;
	public TotalRole: number ;
	public TotalTag: number ;

	public constructor(
		fields?: Partial<AdminLibraryTags>) {

		if (fields) {


			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new AdminLibraryTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new AdminLibraryTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new AdminLibraryTag(x)); }
			Object.assign(this, fields);
		}
	}
}
