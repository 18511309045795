import * as moment from 'moment';
import { LinkStatusType } from './LinkStatusType';
import { LinkToAccessLevel } from './LinkToAccessLevel';
import { LinkToLifecycle } from './LinkToLifecycle';
import { LinkToRole } from './LinkToRole';
import { LinkToTag } from './LinkToTag';
import { User } from './User';

export class Link {
	public AccessLevels: LinkToAccessLevel[]  = [];
	public ApprovedByUser: User ;
	public ApprovedByUserId: number ;
	public ApprovedDate: moment.Moment ;
	public CreatedByUser: User ;
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public Deleted: boolean ;
	public Description: string ;
	public Id: number ;
	public Lifecycles: LinkToLifecycle[]  = [];
	public LinkStatusType: LinkStatusType ;
	public LinkStatusTypeCode: string ;
	public Name: string ;
	public Roles: LinkToRole[]  = [];
	public Tags: LinkToTag[]  = [];
	public Url: string ;

	public constructor(
		fields?: Partial<Link>) {

		if (fields) {
			if (fields.ApprovedByUser) { fields.ApprovedByUser = new User(fields.ApprovedByUser); }
			if (fields.CreatedByUser) { fields.CreatedByUser = new User(fields.CreatedByUser); }
			if (fields.LinkStatusType) { fields.LinkStatusType = new LinkStatusType(fields.LinkStatusType); }
			if (fields.ApprovedDate) { fields.ApprovedDate = moment(fields.ApprovedDate); }
			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new LinkToAccessLevel(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new LinkToLifecycle(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new LinkToRole(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new LinkToTag(x)); }
			Object.assign(this, fields);
		}
	}
}
