
export class ApprovedLinkDto {
	public Id: number ;
	public LinkStatusTypeCode: string ;
	public Name: string ;
	public Url: string ;

	public constructor(
		fields?: Partial<ApprovedLinkDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
