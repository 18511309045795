import { QuickLink } from './QuickLink';

export class QuickLinksDto {
	public Bookmarked: QuickLink[]  = [];
	public MostVisited: QuickLink[]  = [];
	public NewUploads: QuickLink[]  = [];
	public RecentlyViewed: QuickLink[]  = [];

	public constructor(
		fields?: Partial<QuickLinksDto>) {

		if (fields) {


			if (fields.Bookmarked) { fields.Bookmarked = fields.Bookmarked.map(x => new QuickLink(x)); }
			if (fields.MostVisited) { fields.MostVisited = fields.MostVisited.map(x => new QuickLink(x)); }
			if (fields.NewUploads) { fields.NewUploads = fields.NewUploads.map(x => new QuickLink(x)); }
			if (fields.RecentlyViewed) { fields.RecentlyViewed = fields.RecentlyViewed.map(x => new QuickLink(x)); }
			Object.assign(this, fields);
		}
	}
}
