
export class DocToRoleTestDto {
	public DocumentId: number ;
	public Id: number ;
	public RoleId: number ;

	public constructor(
		fields?: Partial<DocToRoleTestDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
