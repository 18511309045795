import { Component, EventEmitter, Output, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material";

@Component({
  selector: "app-snack-bar-error",
  templateUrl: "./snack-bar-error.component.html",
  styleUrls: ["./snack-bar-error.component.scss"]
})
export class SnackBarErrorComponent {
  public message: string;
  @Output()
  dismiss = new EventEmitter<boolean>();
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.message = data;
  }

  close() {
    this.dismiss.emit(true);
  }
}
