
export class SaveUserBookmarkRequest {
	public DocumentId: number ;
	public LinkId: number ;
	public NavigatorId: number ;
	public UserId: number ;

	public constructor(
		fields?: Partial<SaveUserBookmarkRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
