
export class LinkToRoleDto {
	public Id: number ;
	public LinkId: number ;
	public RoleId: number ;

	public constructor(
		fields?: Partial<LinkToRoleDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
