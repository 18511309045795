import { Component, OnInit, Inject, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ApprovalItemDto, DataService, ItemType, DocumentInfoDto } from '../../models/generated';
import { FileObjectDto } from '../../models/FileObjectDto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FileObjectDownloader } from '../../home/fileObjectHelper';
import { BookmarksService } from '../../services/bookmarks.service';
import { SVGInject } from '@iconfu/svg-inject';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent implements OnInit {
  public fileObject: FileObjectDto;
  public documentInfo: DocumentInfoDto = new DocumentInfoDto();
  private showDownloadPane = false;
  private showPdfPane = true;
  private showVideo = false;
  private showImage = false;
  done = new EventEmitter();
  @ViewChild('pdfViewer') pdfViewer;
  static open(matDialog: MatDialog, fileObject: FileObjectDto): Promise<any> {
    return matDialog
      .open(PreviewDocumentComponent, {
        disableClose: false,
        data: { fileObject },
        panelClass: 'pdf-preview-modal',
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private http: HttpClient,
    private dataService: DataService,
    private downloader: FileObjectDownloader,
    private bookmarksService: BookmarksService
  ) {}

  ngOnInit() {
    this.fileObject = this.data.fileObject;
    console.log(this.fileObject);
    if (!this.fileObject.IsNavigator) {
      this.openPdf();
      this.getDocumentInfo();
    } else {
      //this.getNavigatorInfo();
    }
  }
  closeDialog() {
    this.done.emit();
  }

  async getDocumentInfo() {
    this.documentInfo = await this.dataService.document.getDocumentInfo(this.fileObject.Id).toPromise();
  }

  downloadFile() {
    this.downloader.downloadVersion(this.fileObject.DocumentVersionId, this.fileObject.Name);
  }

  async getDocument() {
    if (this.fileObject !== undefined && !this.fileObject.DocumentVersionId) {
      return await this.http
        .get(`${this.dataService.apiRelativePath}/Document/DownloadPreview?id=${this.fileObject.Id}`, { responseType: 'blob' })
        .toPromise();
    } else if (this.fileObject.DocumentVersionId) {
      return await this.http
        .get(`${this.dataService.apiRelativePath}/Document/DownloadVersionPreview?id=${this.fileObject.DocumentVersionId}`, {
          responseType: 'blob',
        })
        .toPromise();
    }
  }

  async openPdf() {
    const r = await this.getDocument().catch(err => {
      return;
    });
    if (r) {
      if (r.type.startsWith('image/')) {
        this.showPdfPane = false;
        this.showImage = true;
        this.showVideo = false;
      } else if (r.type.startsWith('video/')) {
        this.showPdfPane = false;
        this.showImage = false;
        this.showVideo = true;
      } else {
        this.pdfViewer.pdfSrc = r; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      }
    } else {
      console.log(r);
      this.showPdfPane = false;
      this.showVideo = false;
      this.showDownloadPane = true;
    }
  }

  async bookmarkItem() {
    if (this.fileObject.IsNavigator) {
      this.bookmarksService.saveUserBookmark(null, null, this.fileObject.Id);
    } else {
      this.bookmarksService.saveUserBookmark(this.fileObject.Id, null, null);
    }
  }

  isBookmarked(): Boolean {
    return this.bookmarksService.isBookmarked(this.fileObject.Id, ItemType.Document);
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }
}
