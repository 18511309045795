import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material';
import {
  SaveLinkRequest,
  DataService,
  LibraryTag,
  LinkToLifecycleDto,
  LinkToRoleDto,
  LinkToTagDto,
  UpdateDeleteLinkRequest,
  LinkToAccessLevel,
  LinkToAccessLevelDto,
} from '../../models/generated';
import { CompassForm, CompassStringControl, CompassTextareaControl, CompassNumberControl } from 'compass-form';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss'],
})
export class AddLinkComponent implements OnInit {
  link: SaveLinkRequest;
  acquisitions: LibraryTag[];
  roles: LibraryTag[];
  linkForm: CompassForm<Partial<SaveLinkRequest>>;
  visible = true;
  inflight = false;
  accessLevels: any[];

  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  tags: any[] = [];
  filteredTags: Observable<any[]>;
  keywordTags: LibraryTag[] = [];
  warnUser = false;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  static open(dialog: MatDialog, link: SaveLinkRequest): Promise<any> {
    return dialog
      .open(AddLinkComponent, {
        width: '1000px',
        data: link,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<AddLinkComponent>,
    public dataService: DataService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: SaveLinkRequest
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(tag => (tag ? this.filter(tag) : null))
    );
  }

  ngOnInit() {
    this.link = this.data;
    this.getTags();
    this.linkForm = this.getLinkForm();
    this.getAccessLevels();
    this.tags = this.link.Tags;
  }

  getTags() {
    this.dataService.libraries.getLibraryTags().subscribe(result => {
      this.acquisitions = result.Lifecycles;
      this.roles = result.Roles;
      this.keywordTags = result.Tags;
    });
  }

  getAccessLevels() {
    this.dataService.accessLevel.getAccessLevels().subscribe(levels => {
      this.accessLevels = levels.filter(el => el.Enabled === true);
      return this.accessLevels;
    });
  }
  filter(tag: string) {
    return this.keywordTags.filter(option => option.Name.toLowerCase().includes(tag.toLowerCase()));
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      if (input && input.value !== '') {
        if (input.value.trim() !== '') {
          this.snackBar.open('tag does not exist!', '', {
            duration: 1500,
          });
        }
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.tags.findIndex(x => x.Name === event.option.viewValue) > -1) {
      this.snackBar.open('tag has already been added!', '', {
        duration: 1500,
      });
    } else {
      this.tags.push(this.keywordTags.find(t => t.Name === event.option.viewValue));
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  remove(tag: LibraryTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  onAcquisitionChanged(id: number) {
    //  Check If this checkbox already exists on LifeCycles
    const index = this.link.Lifecycles.map(x => x.LifecycleId).indexOf(id);
    if (index > -1) {
      // If it already exsits remove it from Lifecylces
      this.link.Lifecycles.splice(index, 1);
    } else {
      // If it doesn't exist create a new LinkToLifecycleDTO
      const ltl = new LinkToLifecycleDto();
      ltl.LifecycleId = id;
      // If this is an edit add the link id
      if (this.link.Id) {
        ltl.LinkId = this.link.Id;
      }
      this.link.Lifecycles.push(ltl);
    }
  }

  isAcquisitionChecked(a: LinkToLifecycleDto) {
    if (this.link) {
      return this.link.Lifecycles.findIndex(x => x.LifecycleId === a.Id) >= 0;
    }
  }

  onAccessLevelChanged(a) {
    const index = this.link.AccessLevels.map(x => x.AccessLevelId).indexOf(a.Id);
    if (index > -1) {
      this.link.AccessLevels.splice(index, 1);
    } else {
      let al = new LinkToAccessLevelDto();
      al.AccessLevelId = a.Id;
      this.link.AccessLevels.push(al);
    }
  }

  isAccessLevelChecked(a) {
    if (this.link) {
      return this.link.AccessLevels.findIndex(x => x.AccessLevelId === a.Id) >= 0;
    }
  }

  onRoleChanged(id: number) {
    //  Check If this checkbox already exists on Role
    const index = this.link.Roles.map(x => x.RoleId).indexOf(id);
    if (index > -1) {
      // If it already exsits remove it from Role
      this.link.Roles.splice(index, 1);
    } else {
      // If it doesn't exist create a new LinkToRoleDTO
      const ltr = new LinkToRoleDto();
      ltr.RoleId = id;
      // If this is an edit add the link id
      if (this.link.Id) {
        ltr.LinkId = this.link.Id;
      }
      this.link.Roles.push(ltr);
    }
  }

  isRoleChecked(r: LinkToRoleDto) {
    if (this.link) {
      return this.link.Roles.findIndex(x => x.RoleId === r.Id) >= 0;
    }
  }

  getLinkForm() {
    return new CompassForm<Partial<SaveLinkRequest>>({
      Name: new CompassStringControl({
        label: 'Name',
        initialValue: this.link.Name,
        required: true,
        width: '100%',
      }),
      URL: new CompassStringControl({
        label: 'URL',
        initialValue: this.link.URL,
        required: true,
        width: '100%',
      }),
      Description: new CompassTextareaControl({
        label: 'Description',
        initialValue: this.link.Description,
        required: true,
        width: '50%',
      }),
      Id: new CompassNumberControl({
        label: 'Id',
        initialValue: this.link.Id,
        display: false,
      }),
    });
  }

  save() {
    this.inflight = true;

    const link = new SaveLinkRequest();
    link.Description = this.linkForm.value.Description;
    link.Name = this.linkForm.value.Name;
    link.URL = this.linkForm.value.URL;
    if (link.URL.indexOf('http://') === -1 && link.URL.indexOf('https://') === -1) {
      link.URL = 'http://' + link.URL;
    }
    link.Id = this.linkForm.value.Id;
    link.Lifecycles = this.link.Lifecycles;
    link.Roles = this.link.Roles;
    const newTags: LinkToTagDto[] = this.tags;
    link.Tags = [];
    newTags.forEach(x => {
      x.LinkId = link.Id;
      if (!x.TagId) {
        x.TagId = x.Id;
        x.Id = null;
      }
      link.Tags.push(x);
    });
    const newAccessLevels: LinkToAccessLevelDto[] = this.link.AccessLevels;
    link.AccessLevels = [];
    newAccessLevels.forEach(x => {
      x.LinkId = link.Id;
      if (x.Id === x.AccessLevelId) {
        x.Id = null;
      }
      link.AccessLevels.push(x);
    });
    this.dataService.link.saveLink(link).subscribe(response => {
      this.close();
      this.snackBar.open('The link was saved and is pending approval.', '', {
        duration: 1500,
      });
    });
  }

  showWarnUser() {
    this.warnUser = !this.warnUser;
  }

  updateDeleteLink() {
    const updateDeleteLink = new UpdateDeleteLinkRequest();
    updateDeleteLink.Id = this.link.Id;
    this.dataService.link.updateDeleteLink(updateDeleteLink).subscribe(result => {
      this.close();
      // check if link was deleted on modal load
      if (!this.link.Deleted) {
        this.snackBar.open('The link was deleted!', '', {
          duration: 1500,
        });
      } else {
        this.snackBar.open('The link was restored!', '', {
          duration: 1500,
        });
      }
    });
  }

  shouldISeeDelete() {
    if (!this.warnUser && this.link.Id && !this.link.Deleted) {
      return true;
    }
    return false;
  }

  close(result: boolean = true): void {
    this.dialogRef.close(result);
  }
}
