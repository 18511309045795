import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { UsersComponent } from './users/users.component';
import { AddEditUserComponent } from './add-edit-user/add-edit-user.component';
import { CompassFormModule } from 'compass-form';

import { ApproveContentComponent } from './approve-content/approve-content.component';
import { ManageLibrariesComponent } from './manage-libraries/manage-libraries.component';
import { SettingsComponent } from './settings/settings.component';
import { MaterialModule } from '../material.module';
import { EditHomepageComponent } from './edit-homepage/edit-homepage.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { LifeCycleGroupComponent } from '../admin/life-cycle-group/life-cycle-group.component';
import { FileObjectDownloader } from '../home/fileObjectHelper';
import { EditToolkitComponent } from './edit-toolkit/edit-toolkit.component';
import { EditToolkitModalComponent } from './edit-toolkit-modal/edit-toolkit-modal.component';
import { SharedModule } from '../shared.module';
import { EditNewsComponent } from './edit-news/edit-news.component';
import { QuillModule } from 'ngx-quill';
import { AddEditLifecycleComponent } from './add-edit-lifecycle/add-edit-lifecycle.component';
import { AddEditRoleComponent } from './add-edit-role/add-edit-role.component';
import { AddEditTagComponent } from './add-edit-tag/add-edit-tag.component';
import { EditHomepageSectionsComponent } from './edit-homepage-sections/edit-homepage-sections.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MyUploadsComponent } from './my-uploads/my-uploads.component';
import { EditHomepageDocumentModalComponent } from './edit-homepage-document-modal/edit-homepage-document-modal.component';
import { EditAccessLevelModalComponent } from './edit-access-level-modal/edit-access-level-modal.component';

@NgModule({
  declarations: [
    UsersComponent,
    AddEditUserComponent,
    ApproveContentComponent,
    ManageLibrariesComponent,
    SettingsComponent,
    EditHomepageComponent,
    UnauthorizedComponent,
    PreviewDocumentComponent,
    LifeCycleGroupComponent,
    EditToolkitComponent,
    EditToolkitModalComponent,
    EditNewsComponent,
    AddEditLifecycleComponent,
    AddEditRoleComponent,
    AddEditTagComponent,
    EditHomepageSectionsComponent,
    EditHomepageDocumentModalComponent,
    UserProfileComponent,
    MyUploadsComponent,
    EditAccessLevelModalComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    CompassFormModule,
    MaterialModule,
    PdfJsViewerModule,
    SharedModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [FileObjectDownloader],
  exports: [],
  entryComponents: [
    UsersComponent,
    AddEditUserComponent,
    PreviewDocumentComponent,
    EditToolkitModalComponent,
    AddEditLifecycleComponent,
    AddEditRoleComponent,
    EditAccessLevelModalComponent,
    AddEditTagComponent,
    EditHomepageDocumentModalComponent,
  ],
})
export class AdminModule {}
