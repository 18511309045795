import * as moment from 'moment';
import { DocumentStatusType } from './DocumentStatusType';
import { DocumentToAccessLevel } from './DocumentToAccessLevel';
import { DocumentToLifecycle } from './DocumentToLifecycle';
import { DocumentToRole } from './DocumentToRole';
import { DocumentToTag } from './DocumentToTag';
import { DocumentVersion } from './DocumentVersion';
import { User } from './User';

export class Document {
	public AccessLevels: DocumentToAccessLevel[]  = [];
	public ApprovedByUser: User ;
	public ApprovedByUserId: number ;
	public ApprovedDate: moment.Moment ;
	public CreatedByUser: User ;
	public CreatedByUserId: number ;
	public CreatedDate: moment.Moment ;
	public CurrentVersion: DocumentVersion ;
	public Deleted: boolean ;
	public Description: string ;
	public DocumentStatusType: DocumentStatusType ;
	public DocumentStatusTypeCode: string ;
	public FileSize: number ;
	public Id: number ;
	public Lifecycles: DocumentToLifecycle[]  = [];
	public Name: string ;
	public Roles: DocumentToRole[]  = [];
	public Tags: DocumentToTag[]  = [];
	public Title: string ;
	public Versions: DocumentVersion[]  = [];

	public constructor(
		fields?: Partial<Document>) {

		if (fields) {
			if (fields.ApprovedByUser) { fields.ApprovedByUser = new User(fields.ApprovedByUser); }
			if (fields.CreatedByUser) { fields.CreatedByUser = new User(fields.CreatedByUser); }
			if (fields.CurrentVersion) { fields.CurrentVersion = new DocumentVersion(fields.CurrentVersion); }
			if (fields.DocumentStatusType) { fields.DocumentStatusType = new DocumentStatusType(fields.DocumentStatusType); }
			if (fields.ApprovedDate) { fields.ApprovedDate = moment(fields.ApprovedDate); }
			if (fields.CreatedDate) { fields.CreatedDate = moment(fields.CreatedDate); }
			if (fields.AccessLevels) { fields.AccessLevels = fields.AccessLevels.map(x => new DocumentToAccessLevel(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new DocumentToLifecycle(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new DocumentToRole(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new DocumentToTag(x)); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersion(x)); }
			Object.assign(this, fields);
		}
	}
}
