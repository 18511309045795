import { Document } from './Document';
import { HomepageSection } from './HomepageSection';

export class HomepageSectionToDocument {
	public Document: Document ;
	public DocumentId: number ;
	public HomepageSection: HomepageSection ;
	public HomepageSectionId: number ;
	public Id: number ;

	public constructor(
		fields?: Partial<HomepageSectionToDocument>) {

		if (fields) {
			if (fields.Document) { fields.Document = new Document(fields.Document); }
			if (fields.HomepageSection) { fields.HomepageSection = new HomepageSection(fields.HomepageSection); }


			Object.assign(this, fields);
		}
	}
}
