/* tslint:disable */
import { FormControl } from '@angular/forms';
import { validateEmailAddress } from './emailValidatorUtil';

export class ApValidators {
	static IsNumeric(v: FormControl) {
		let value = v.value;
		if (value === null) {
			return null;
		}
		if (typeof value === 'number') {
			return null;
		}
		if (value === undefined) {
			return null;
		}
		if (value.toString() === '') {
			return null;
		}
		return { IsNumeric: 'Value is not a number' };
	}

	static IsValidVendor(v: FormControl) {
		let value = v.value;
		if (value === null) {
			return null;
		}
		if (typeof value === 'number') {
			return null;
		}
		if (value === undefined) {
			return null;
		}
		if (value.toString() === '') {
			return null;
		}
		return { invalid: 'Invalid Vendor' };
	}

	static IsDollar(v: FormControl) {
		let value = v.value;
		if (value === null) {
			return null;
		}
		if (value === undefined) {
			return null;
		}
		if (value.toString() === '') {
			return null;
		}
		if (typeof value === 'number') {
			if (value.toString().includes('.')) {
				if (value.toString().split('.')[1].length > 2) {
					return { invalid: true };
				}
			}
			return null;
		}
		return { invalid: 'Invalid amount' };
	}

	static IsInteger(v: FormControl) {
		let value = v.value;
		if (value === null) {
			return null;
		}
		if (typeof value === 'number' && Number.isInteger(value)) {
			return null;
		}
		if (value === undefined) {
			return null;
		}
		if (value.toString() === '') {
			return null;
		}
		return { invalidInteger: 'Invalid number' };
	}

	static IsNotNullOrZero(v: FormControl) {
		let value = v.value;
		if (value === null || value === undefined || value.toString().trim() === '' || value === 0) {
			return { invalid: 'Must have value' };
		}
		return null;
	}



	static IsEmail(v: FormControl) {
		let value = v.value;
		if (value === null) {
			return null;
		}
		if (typeof value === 'string' && validateEmailAddress(value)) {
			return null;
		}
		if (value.toString().trim() === '') {
			return null;
		}
		return { invalid: 'Invalid email' };
	}
}
