import { Link } from './Link';
import { Role } from './Role';

export class LinkToRole {
	public Id: number ;
	public Link: Link ;
	public LinkId: number ;
	public Role: Role ;
	public RoleId: number ;

	public constructor(
		fields?: Partial<LinkToRole>) {

		if (fields) {
			if (fields.Link) { fields.Link = new Link(fields.Link); }
			if (fields.Role) { fields.Role = new Role(fields.Role); }


			Object.assign(this, fields);
		}
	}
}
