import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../material.module';
import { SnackBarErrorComponent } from './snack-bar-error/snack-bar-error.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { AddLinkComponent } from './add-link/add-link.component';
import { CompassFormModule } from 'compass-form';
import { LifecycleComponent } from './lifecycle/lifecycle.component';
import { SharedModule } from '../shared.module';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { ToolkitComponent } from './toolkit/toolkit.component';
import { NewsComponent } from './news/news.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { QuillModule } from 'ngx-quill';
import { LifecycleDetailsComponent } from '../home/lifecycle-details/lifecycle-details.component';
import { SafeHtmlPipe } from '../shared/safeHtml.pipe';
import { NewsModalComponent } from './news-modal/news-modal.component';
import { CustomSectionsComponent } from './custom-sections/custom-sections.component';

@NgModule({
  declarations: [
    HomeComponent,
    SnackBarErrorComponent,
    DocumentUploadComponent,
    SearchComponent,
    EditDocumentComponent,
    AddLinkComponent,
    LifecycleComponent,
    QuickLinksComponent,
    ToolkitComponent,
    NewsComponent,
    CustomSectionsComponent,
    SafeHtmlPipe,
    NewsModalComponent,
    LifecycleDetailsComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    CompassFormModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxHmCarouselModule,
    QuillModule,
  ],
  entryComponents: [
    DocumentUploadComponent,
    AddLinkComponent,
    SnackBarErrorComponent,
    EditDocumentComponent,
    NewsModalComponent,
    LifecycleDetailsComponent,
  ],
})
export class HomeModule {}
