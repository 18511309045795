import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { DataService, SaveRoleRequest, LibraryTag } from '../../models/generated';

@Component({
  selector: 'add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
})
export class AddEditRoleComponent implements OnInit {
  modalTitle = '';
  selectedLibrary: SaveRoleRequest;
  selectedLibraryName: string;
  rolesList: LibraryTag[] = [];
  roleExists = false;
  static open(matDialog: MatDialog, libraryTag: SaveRoleRequest): Promise<any> {
    return matDialog
      .open(AddEditRoleComponent, {
        width: '800px',
        data: libraryTag,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditRoleComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.selectedLibrary = this.data;
    this.selectedLibraryName = this.selectedLibrary.Name;
    this.setModalTitle();
    this.setEnabledIfNew(this.selectedLibrary);
    this.getRoles();
  }

  getRoles() {
    this.dataService.libraries.getLibraryTags().subscribe(results => {
      this.rolesList = results.Roles;
    });
  }

  checkIfRoleExists(name: string) {
    if (name.trim().toLowerCase() === this.selectedLibraryName.trim().toLowerCase()) {
      return (this.roleExists = false);
    }
    this.roleExists = this.rolesList.findIndex(l => l.Name.trim().toLowerCase() === name.trim().toLowerCase() && l.Enabled) > -1;
  }

  setModalTitle() {
    if (this.selectedLibrary.Id) {
      this.modalTitle = 'Edit Role';
    } else {
      this.modalTitle = 'Add Role';
    }
  }

  setEnabledIfNew(tag) {
    if (!tag.Id) {
      tag.Enabled = true;
    }
  }

  saveLibrary() {
    this.dataService.libraries.saveRole(this.selectedLibrary).subscribe(result => {
      this.onClose();
      this.snackBar.open('The role was saved!', '', {
        duration: 1500,
      });
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isDisabled() {
    return !this.selectedLibrary.Name || this.roleExists;
  }
}
