import * as $ from "jquery";

export class UploaderServiceListener {
  constructor(public destory: Function) {}
}

export class UploaderService {
  listeners: Function[] = [];

  constructor() {
    $("html").on("dragenter.uploader", event => {
      if (this.listeners.length > 0) {
        this.listeners[this.listeners.length - 1]();
      }
    });
  }

  registerOnDrag(fn: Function) {
    this.listeners.push(fn);
    return new UploaderServiceListener(() => {
      const i = this.listeners.indexOf(fn);
      this.listeners.splice(i, 1);
    });
  }
}
