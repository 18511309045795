import { LibraryTag } from './LibraryTag';

export class LibraryTags {
	public Lifecycles: LibraryTag[]  = [];
	public Roles: LibraryTag[]  = [];
	public Tags: LibraryTag[]  = [];

	public constructor(
		fields?: Partial<LibraryTags>) {

		if (fields) {


			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new LibraryTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new LibraryTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new LibraryTag(x)); }
			Object.assign(this, fields);
		}
	}
}
