
export class LifecycleGroupToLifecycleTestDto {
	public Id: number ;
	public LifecycleGroupId: number ;
	public LifecycleId: number ;

	public constructor(
		fields?: Partial<LifecycleGroupToLifecycleTestDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
