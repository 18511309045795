
export class SaveWhitelistExtensionRequest {
	public Extension: string ;
	public Id: number ;

	public constructor(
		fields?: Partial<SaveWhitelistExtensionRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
