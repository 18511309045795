import { Component, OnInit } from '@angular/core';
import {
  DocumentInfoDto,
  DataService,
  HomepageSectionsDto,
  SaveHomepageSectionRequest,
  HomepageSectionToDocumentDto,
} from '../../models/generated';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditHomepageDocumentModalComponent } from '../edit-homepage-document-modal/edit-homepage-document-modal.component';

@Component({
  selector: 'edit-homepage-sections',
  templateUrl: './edit-homepage-sections.component.html',
  styleUrls: ['./edit-homepage-sections.component.scss'],
})
export class EditHomepageSectionsComponent implements OnInit {
  // loading = true;
  homepageSections: HomepageSectionsDto[] = [];
  constructor(private dataService: DataService, private matDialog: MatDialog, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getHomepageSections();
  }

  async getHomepageSections() {
    this.homepageSections = await this.dataService.homepageSection.getHomepageSections().toPromise();
  }

  async saveHomepageSection(section: SaveHomepageSectionRequest) {
    await this.dataService.homepageSection.saveHomepageSection(section).toPromise();
    this.getHomepageSections();
  }

  async addHomepageSection() {
    await this.dataService.homepageSection.saveHomepageSection(new SaveHomepageSectionRequest()).toPromise();
    this.getHomepageSections();
  }

  async openEditHomepageDocumentModal(doc: HomepageSectionToDocumentDto, sectionId: number) {
    if (!doc) {
      doc = new HomepageSectionToDocumentDto();
      doc.HomepageSectionId = sectionId;
    }

    EditHomepageDocumentModalComponent.open(this.matDialog, doc).finally(() => {
      this.getHomepageSections();
    });
  }

  showThumbnailImage(documentId: number) {
    const element = document.getElementById('thumbnail-image-' + documentId);
    element.parentNode.removeChild(element);
    const newElement = document.createElement('div');
    newElement.style.cssText = `width: 150px;
      height: 200px;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      font-size: 16px;
      font-weight: bold;
      background-color: #f2f2f2;
      text-shadow: 1px 1px #b3b3b3;
      border-radius: 5px;`;
    const newContent = document.createTextNode('No Preview Available');
    newElement.appendChild(newContent);

    const currentDiv = document.getElementById('image-' + documentId);
    currentDiv.appendChild(newElement);
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  deleteHomepageSectionDocument(doc: HomepageSectionToDocumentDto) {
    const homepageSection = this.homepageSections.find(x => x.Id === doc.HomepageSectionId);
    const index = homepageSection.Documents.indexOf(doc);
    homepageSection.Documents.splice(index, 1);
    const saveHomepageSectionRequest = new SaveHomepageSectionRequest(Object.assign({}, <any>homepageSection));
    saveHomepageSectionRequest.Id = homepageSection.Id;
    saveHomepageSectionRequest.Title = homepageSection.Title;
    saveHomepageSectionRequest.Documents = <any>homepageSection.Documents;
    this.dataService.homepageSection.saveHomepageSection(saveHomepageSectionRequest).subscribe();
  }

  deleteHomepageSection(section: HomepageSectionsDto) {
    this.dataService.homepageSection.deleteHomepageSection(section.Id).subscribe(results => {
      this.getHomepageSections();
    });
    this.snackBar.open('Custom Section has been deleted.', '', {
      duration: 1500,
    });
  }
}
