import { Injectable } from '@angular/core';
import { DataService, ApprovalItemDto, MyUploadsResultList, CurrentUserDto } from '../models/generated';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class MyUploadsService {
  private myUploads = new BehaviorSubject<MyUploadsResultList>(null);
  myUploads$ = this.myUploads.asObservable();
  public mu: MyUploadsResultList;

  constructor(private dataService: DataService) {
    this.refresh(null);
  }

  async refresh(user: CurrentUserDto) {
    if (!user) {
      user = await this.dataService.user.getCurrentUser().toPromise();
    }
    this.mu = await this.dataService.user.getMyUploads(user.Id).toPromise();
    this.myUploads.next(this.mu);
  }
}
