import { Component, OnInit } from '@angular/core';
import {
  CurrentUserDto,
  DataService,
  MyUploadsResultList,
  MyUploadsResult,
  SaveItemViewRequest,
  ItemType,
  ItemViewLocation,
  EditDocumentDto,
  SaveLinkRequest,
} from '../../models/generated';
import { BookmarksService } from '../../services/bookmarks.service';
import { ItemViewService } from '../../services/itemView.service';
import { FileObjectDto } from '../../models/FileObjectDto';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { MatDialog } from '@angular/material';
import { FileObjectDownloader } from '../../home/fileObjectHelper';
import { EditDocumentComponent } from '../../home/edit-document/edit-document.component';
import { AddLinkComponent } from '../../home/add-link/add-link.component';
import { MyUploadsService } from '../../services/my-uploads.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-my-uploads',
  templateUrl: './my-uploads.component.html',
  styleUrls: ['./my-uploads.component.scss'],
})
export class MyUploadsComponent implements OnInit {
  uploadsSubscription: Subscription;
  userSubscription: Subscription;
  currentUser: CurrentUserDto = new CurrentUserDto();
  myUploads: MyUploadsResultList = new MyUploadsResultList();
  approvedUploads: MyUploadsResult[] = [];
  pendingUploads: MyUploadsResult[] = [];
  rejectedDeletedUploads: MyUploadsResult[] = [];
  url: string;
  loading = true;
  approvedTerm: string;
  filteredApprovedUploads: MyUploadsResult[] = [];
  filteredPendingUploads: MyUploadsResult[] = [];
  filteredRejectedDeletedUploads: MyUploadsResult[] = [];
  constructor(
    private dataService: DataService,
    private bookmarksService: BookmarksService,
    private matDialog: MatDialog,
    private itemViewService: ItemViewService,
    private downloader: FileObjectDownloader,
    private myUploadsService: MyUploadsService,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });
    this.myUploadsService.myUploads$.subscribe(pd => {
      this.getMyUploads(pd);
    });
  }

  async ngOnInit() {
    this.url = window.location.origin;
    this.getMyUploads(this.myUploadsService.mu);
    this.setCurrentUser(this.userService.cu);
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  getMyUploads(pd: MyUploadsResultList) {
    if (!pd) {
      return;
    }
    this.myUploads = pd;
    this.approvedUploads = this.myUploads.Results.filter(x => x.StatusTypeCode.includes('Approved') && !x.Deleted);
    this.pendingUploads = this.myUploads.Results.filter(x => x.StatusTypeCode.includes('Pending') && !x.Deleted);
    this.rejectedDeletedUploads = this.myUploads.Results.filter(x => x.StatusTypeCode.includes('Rejected') || x.Deleted);
    this.loading = false;
    this.assignApprovedUploadsCopy();
    this.assignPendingUploadsCopy();
    this.assignRejectedDeletedUploadsCopy();
  }

  assignApprovedUploadsCopy() {
    this.filteredApprovedUploads = Object.assign([], this.approvedUploads);
  }
  setApprovedSearch(value: string) {
    if (!value) {
      this.assignApprovedUploadsCopy();
    } // when nothing has typed
    this.filteredApprovedUploads = Object.assign([], this.approvedUploads).filter(
      item =>
        item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        (item.Description && item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );
  }

  assignPendingUploadsCopy() {
    this.filteredPendingUploads = Object.assign([], this.pendingUploads);
  }
  setPendingSearch(value: string) {
    if (!value) {
      this.assignPendingUploadsCopy();
    } // when nothing has typed
    this.filteredPendingUploads = Object.assign([], this.pendingUploads).filter(
      item =>
        item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        (item.Description && item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );
  }

  assignRejectedDeletedUploadsCopy() {
    this.filteredRejectedDeletedUploads = Object.assign([], this.rejectedDeletedUploads);
  }
  setRejectedDeletedSearch(value: string) {
    if (!value) {
      this.assignRejectedDeletedUploadsCopy();
    } // when nothing has typed
    this.filteredRejectedDeletedUploads = Object.assign([], this.rejectedDeletedUploads).filter(
      item =>
        item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        (item.Description && item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1)
    );
  }

  async bookmarkItem(item: MyUploadsResult) {
    if (item.ItemType === ItemType.Document) {
      this.bookmarksService.saveUserBookmark(item.Id, null, null);
    } else if (item.ItemType === ItemType.Link) {
      this.bookmarksService.saveUserBookmark(null, item.Id, null);
    }
  }

  isBookmarked(item: MyUploadsResult): Boolean {
    return this.bookmarksService.isBookmarked(item.Id, item.ItemType);
  }

  async previewDocument(item: MyUploadsResult) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.MyUploads }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.Name });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  async previewNavigator(item: MyUploadsResult) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ NavigatorId: item.Id, Location: ItemViewLocation.MyUploads }));
    const fileObject = new FileObjectDto({ Id: item.Id, Name: item.Name });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }
  async download(item: MyUploadsResult) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.MyUploads }));
    this.downloader.download(item.Id, item.Name);
  }

  async openEditDocumentModal(documentId: number) {
    let document = new EditDocumentDto();
    await this.dataService.document.getEditDocument(documentId).subscribe(result => {
      document = result;
      EditDocumentComponent.open(this.matDialog, document).finally(() => {
        this.getMyUploads(this.myUploadsService.mu);
      });
    });
  }

  async openLinkModal(linkId: number) {
    const link = new SaveLinkRequest();
    await this.dataService.link
      .getEditLink(linkId)
      .toPromise()
      .then(result => {
        link.Id = result.Id;
        link.Description = result.Description;
        link.Name = result.Name;
        link.Roles = result.Roles;
        link.Tags = result.Tags;
        link.Lifecycles = result.Lifecycles;
        link.URL = result.URL;
        link.Deleted = result.Deleted;
        AddLinkComponent.open(this.matDialog, link).finally(() => {
          this.getMyUploads(this.myUploadsService.mu);
        });
      });
  }

  isLast(index, array) {
    if (index === array.length - 1) {
      return true;
    } else {
      return false;
    }
  }
}
