
export class ReplaceDocumentVersionAndDeleteRequest {
	public NewDocumentId: number ;
	public NewTitle: string ;
	public OriginalDocumentId: number ;

	public constructor(
		fields?: Partial<ReplaceDocumentVersionAndDeleteRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
