
export class SaveRoleRequest {
	public Enabled: boolean ;
	public Id: number ;
	public Name: string ;

	public constructor(
		fields?: Partial<SaveRoleRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
