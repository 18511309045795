
export class SearchRequest {
	public Count: number ;
	public ItemTypes: string[]  = [];
	public Lifecycles: number[]  = [];
	public Roles: number[]  = [];
	public ShowDeleted: boolean ;
	public SortByNewest: boolean ;
	public Start: number ;
	public Tags: number[]  = [];
	public Term: string ;
	public UserId: number ;

	public constructor(
		fields?: Partial<SearchRequest>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
