import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
} from '@angular/material';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { MAX_FILE_SIZE } from '../../utils/constants';
import { DataService, UpdateDocumentObjectRequest, LibraryTag, AccessLevelDto, DocumentToAccessLevelDto } from '../../models/generated';
import { DocumentToLifecycleDto } from '../../models/generated/DocumentToLifecycleDto';
import { DocumentToRoleDto } from '../../models/generated/DocumentToRoleDto';
import { DocumentToTagDto } from '../../models/generated/DocumentToTagDto';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ItemViewService } from '../../services/itemView.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  fileUploadErrorMessage: string;
  acquisitions: LibraryTag[];
  accessLevels: any[];
  inflight = false;
  roles: LibraryTag[];
  keywordTags: LibraryTag[] = [];
  document: UpdateDocumentObjectRequest;
  visible = true;
  selectable = true;
  whiteList = [];
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  tags: any[] = [];
  filteredTags: Observable<any[]>;
  documentTitle = '';
  uploader: FileUploader = new FileUploader({
    url: 'api/Document/Upload',
    autoUpload: false,
    removeAfterUpload: true,
    maxFileSize: MAX_FILE_SIZE,
    queueLimit: 1,
  });

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  static open(dialog: MatDialog, document: UpdateDocumentObjectRequest): Promise<any> {
    return dialog
      .open(DocumentUploadComponent, {
        width: '1000px',
        data: document,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<DocumentUploadComponent>,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: UpdateDocumentObjectRequest,
    private itemViewService: ItemViewService
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(tag => (tag ? this.filter(tag) : this.keywordTags.slice()))
    );
  }

  ngOnInit() {
    this.document = this.data;
    this.getAcquisitionLifecycles();
    this.getApprovedFileTypes();
    this.getAccessLevels();
    this.uploader.onCompleteAll = () => this.itemViewService.refresh(); // Don't await the promise because this can be done without blocking
  }

  filter(tag: string) {
    return this.keywordTags.filter(option => option.Name.toLowerCase().includes(tag.toLowerCase()));
  }

  getApprovedFileTypes() {
    this.dataService.settings.getWhitelistTags().subscribe(result => {
      result.forEach(r => {
        this.whiteList.push(r.Extension);
      });
    });
  }

  getAccessLevels() {
    this.dataService.accessLevel.getAccessLevels().subscribe(levels => {
      this.accessLevels = levels.filter(el => el.Enabled === true);
      return this.accessLevels;
    });
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      if (input && input.value !== '') {
        if (input.value.trim() !== '') {
          this.snackBar.open('tag does not exist!', '', {
            duration: 1500,
          });
        }
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.tags.findIndex(x => x.Name === event.option.viewValue) > -1) {
      this.snackBar.open('tag has already been added!', '', {
        duration: 1500,
      });
    } else {
      this.tags.push(this.keywordTags.find(t => t.Name === event.option.viewValue));
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  remove(tag: LibraryTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  getAcquisitionLifecycles() {
    this.dataService.libraries.getLibraryTags().subscribe(result => {
      this.acquisitions = result.Lifecycles;
      this.roles = result.Roles;
      this.keywordTags = result.Tags;
    });
  }

  docAdded(doc) {
    this.inflight = true;
    const document = new UpdateDocumentObjectRequest((<any>doc).uploaderItem);
    document.Id = doc.Id;
    const newLifecycles: DocumentToLifecycleDto[] = this.document.Lifecycles;
    newLifecycles.forEach(x => {
      x.DocumentId = document.Id;
      x.LifecycleId = x.Id;
      x.Id = null;
      document.Lifecycles.push(x);
    });
    const newRoles: DocumentToRoleDto[] = this.document.Roles;
    newRoles.forEach(x => {
      x.DocumentId = document.Id;
      x.RoleId = x.Id;
      x.Id = null;
      document.Roles.push(x);
    });
    const newTags: DocumentToTagDto[] = this.tags;
    newTags.forEach(x => {
      x.DocumentId = document.Id;
      x.TagId = x.Id;
      x.Id = null;
      document.Tags.push(x);
    });

    const newAccessLevels: DocumentToAccessLevelDto[] = this.document.AccessLevels;
    newAccessLevels.forEach(x => {
      x.DocumentId = document.Id;
      x.AccessLevelId = x.Id;
      x.Id = null;
      document.AccessLevels.push(x);
    });
    this.dataService.document.update(document).subscribe(response => {
      if (this.uploader.queue.length < 1) {
        this.close();
        this.snackBar.open('The document was uploaded and is pending approval.', '', {
          duration: 1500,
        });
      } else {
        this.snackBar.open('There was a problem uploading the document!', '', {
          duration: 1500,
        });
        this.close();
      }
    });
  }

  upload() {
    if (this.uploader.queue.length < 1) {
      this.close();
    }
    this.uploader.uploadAll();
  }

  removeDocument(file: FileItem) {
    this.uploader.queue = this.uploader.queue.filter(fileItem => fileItem !== file);
  }

  updateErrorMessage(message: string) {
    this.fileUploadErrorMessage = message;
  }

  onAcquisitionChanged(a) {
    const index = this.document.Lifecycles.map(x => x.Id).indexOf(a.Id);
    if (index > -1) {
      this.document.Lifecycles.splice(index, 1);
    } else {
      this.document.Lifecycles.push(a);
    }
  }

  isAcquisitionChecked(a) {
    if (this.document) {
      return this.document.Lifecycles.findIndex(x => x.Id === a.Id) >= 0;
    }
  }

  onRoleChanged(r) {
    const index = this.document.Roles.map(x => x.Id).indexOf(r.Id);
    if (index > -1) {
      this.document.Roles.splice(index, 1);
    } else {
      this.document.Roles.push(r);
    }
  }

  isRoleChecked(r) {
    if (this.document) {
      return this.document.Roles.findIndex(x => x.Id === r.Id) >= 0;
    }
  }

  onAccessLevelChanged(a) {
    const index = this.document.AccessLevels.map(x => x.Id).indexOf(a.Id);
    if (index > -1) {
      this.document.AccessLevels.splice(index, 1);
    } else {
      this.document.AccessLevels.push(a);
    }
  }

  isAccessLevelChecked(a) {
    if (this.document) {
      return this.document.AccessLevels.findIndex(x => x.Id === a.Id) >= 0;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
