
export class DocumentToRoleDto {
	public DocumentId: number ;
	public Id: number ;
	public RoleId: number ;

	public constructor(
		fields?: Partial<DocumentToRoleDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
