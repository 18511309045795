
export class UserTestDto {
	public Email: string ;
	public Enabled: boolean ;
	public FirstName: string ;
	public Id: number ;
	public LastName: string ;
	public UserName: string ;

	public constructor(
		fields?: Partial<UserTestDto>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
