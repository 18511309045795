import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { SaveLifecycleRequest, DataService, LibraryTag } from '../../models/generated';

@Component({
  selector: 'app-add-edit-lifecycle',
  templateUrl: './add-edit-lifecycle.component.html',
  styleUrls: ['./add-edit-lifecycle.component.scss'],
})
export class AddEditLifecycleComponent implements OnInit {
  modalTitle = '';
  modules = {};
  selectedLibrary: SaveLifecycleRequest;
  selectedLibraryName: string;
  librariesList: LibraryTag[] = [];
  libraryExists = false;
  static open(matDialog: MatDialog, libraryTag: SaveLifecycleRequest): Promise<any> {
    return matDialog
      .open(AddEditLifecycleComponent, {
        width: '800px',
        data: libraryTag,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<AddEditLifecycleComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) {
    this.modules = {
      toolbar: [
        [{ header: 1 }, { header: 2 }],
        ['bold', 'italic', 'underline', 'picker-label'],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
      ],
      keyboard: { bindings: { tab: false } },
    };
  }

  ngOnInit() {
    this.selectedLibrary = this.data;
    this.selectedLibraryName = this.selectedLibrary.Name;
    this.setModalTitle();
    this.setEnabledIfNew(this.selectedLibrary);
    this.getLifecycles();
  }

  setModalTitle() {
    if (this.selectedLibrary.Id) {
      this.modalTitle = 'Edit Lifecycle';
    } else {
      this.modalTitle = 'Add Lifecycle';
    }
  }

  getLifecycles() {
    this.dataService.libraries.getLibraryTags().subscribe(results => {
      this.librariesList = results.Lifecycles;
    });
  }

  checkIfLifecycleExists(name: string) {
    if (name.trim().toLowerCase() === this.selectedLibraryName.trim().toLowerCase()) {
      return (this.libraryExists = false);
    }
    this.libraryExists = this.librariesList.findIndex(l => l.Name.trim().toLowerCase() === name.trim().toLowerCase() && l.Enabled) > -1;
  }

  setEnabledIfNew(tag) {
    if (!tag.Id) {
      tag.Enabled = true;
    }
  }

  saveLibrary() {
    this.dataService.libraries.saveLifecycle(this.selectedLibrary).subscribe(result => {
      this.onClose();
      this.snackBar.open('The lifecycle was saved!', '', {
        duration: 1500,
      });
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isDisabled() {
    return !this.selectedLibrary.Name || this.libraryExists;
  }

  removeText() {
    this.selectedLibrary.Description = '';
  }
}
