import { Lifecycle } from './Lifecycle';
import { LifecycleGroup } from './LifecycleGroup';

export class LifecycleGroupToLifecycle {
	public Id: number ;
	public Lifecycle: Lifecycle ;
	public LifecycleGroup: LifecycleGroup ;
	public LifecycleGroupId: number ;
	public LifecycleId: number ;
	public Ordinal: number ;

	public constructor(
		fields?: Partial<LifecycleGroupToLifecycle>) {

		if (fields) {
			if (fields.Lifecycle) { fields.Lifecycle = new Lifecycle(fields.Lifecycle); }
			if (fields.LifecycleGroup) { fields.LifecycleGroup = new LifecycleGroup(fields.LifecycleGroup); }


			Object.assign(this, fields);
		}
	}
}
