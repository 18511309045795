import * as moment from 'moment';
import { DocumentVersionDto } from './DocumentVersionDto';

export class QuickLink {
	public CurrentVersionDocumentName: string ;
	public CurrentVersionId: number ;
	public Date: moment.Moment ;
	public Deleted: boolean ;
	public Id: number ;
	public ItemType: string ;
	public Name: string ;
	public SearchTerm: string ;
	public Url: string ;
	public Versions: DocumentVersionDto[]  = [];

	public constructor(
		fields?: Partial<QuickLink>) {

		if (fields) {

			if (fields.Date) { fields.Date = moment(fields.Date); }
			if (fields.Versions) { fields.Versions = fields.Versions.map(x => new DocumentVersionDto(x)); }
			Object.assign(this, fields);
		}
	}
}
