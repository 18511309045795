import { Document } from './Document';
import { Role } from './Role';

export class DocumentToRole {
	public Document: Document ;
	public DocumentId: number ;
	public Id: number ;
	public Role: Role ;
	public RoleId: number ;

	public constructor(
		fields?: Partial<DocumentToRole>) {

		if (fields) {
			if (fields.Document) { fields.Document = new Document(fields.Document); }
			if (fields.Role) { fields.Role = new Role(fields.Role); }


			Object.assign(this, fields);
		}
	}
}
