import { SearchResultTag } from './SearchResultTag';

export class DocumentInfoDto {
	public AllTags: SearchResultTag[]  = [];
	public Description: string ;
	public DocumentName: string ;
	public DocumentVersionId: number ;
	public Id: number ;
	public Lifecycles: SearchResultTag[]  = [];
	public Name: string ;
	public Roles: SearchResultTag[]  = [];
	public Tags: SearchResultTag[]  = [];

	public constructor(
		fields?: Partial<DocumentInfoDto>) {

		if (fields) {


			if (fields.AllTags) { fields.AllTags = fields.AllTags.map(x => new SearchResultTag(x)); }
			if (fields.Lifecycles) { fields.Lifecycles = fields.Lifecycles.map(x => new SearchResultTag(x)); }
			if (fields.Roles) { fields.Roles = fields.Roles.map(x => new SearchResultTag(x)); }
			if (fields.Tags) { fields.Tags = fields.Tags.map(x => new SearchResultTag(x)); }
			Object.assign(this, fields);
		}
	}
}
