
export class DocumentStatusType {
	public static readonly Approved: string = 'Approved';
	public static readonly Deleted: string = 'Deleted';
	public static readonly Pending: string = 'Pending';
	public static readonly Rejected: string = 'Rejected';

	public Code: string ;
	public Name: string ;

	public constructor(
		fields?: Partial<DocumentStatusType>) {

		if (fields) {



			Object.assign(this, fields);
		}
	}
}
