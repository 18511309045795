import { Component, OnInit, Inject, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
} from '@angular/material';
import {
  UpdateDocumentObjectRequest,
  DataService,
  LibraryTag,
  DocumentToRole,
  DocumentToTagDto,
  DocumentToLifecycleDto,
  DocumentToRoleDto,
  EditDocumentDto,
  CurrentUserDto,
  ReplaceDocumentVersionAndDeleteRequest,
  SaveItemViewRequest,
  ItemViewLocation,
  RestoreDocumentVersionRequest,
  DocumentVersionDto,
  DocumentStatusType,
  DocumentToAccessLevelDto,
} from '../../models/generated';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { MAX_FILE_SIZE } from '../../utils/constants';
import { FileObjectDto } from '../../models/FileObjectDto';
import { ItemViewService } from '../../services/itemView.service';
import { PreviewDocumentComponent } from '../../admin/preview-document/preview-document.component';
import { SnackBarErrorComponent } from '../snack-bar-error/snack-bar-error.component';
import { trigger, transition, style, stagger, animate, keyframes, query } from '@angular/animations';
import { FileObjectDownloader } from '../fileObjectHelper';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 1 }), { optional: true }),

        query(
          ':enter',
          stagger('300ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateY(35px)', offset: 0.3 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
        query(
          ':leave',
          stagger('300ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                style({ opacity: 0.5, transform: 'translateX(35px)', offset: 0.3 }),
                style({ opacity: 0, transform: 'translateX(-5%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EditDocumentComponent implements OnInit {
  fileUploadErrorMessage: string;
  document: EditDocumentDto;
  pendingRestoredDocumentVersion: DocumentVersionDto;
  acquisitions: LibraryTag[];
  roles: LibraryTag[];
  keywordTags: LibraryTag[] = [];
  isEdit: boolean;
  filteredTags: Observable<any[]>;
  tagCtrl = new FormControl();
  inflight = false;
  visible = true;
  selectable = true;
  removable = true;
  accessLevels: any[];
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: any[] = [];
  warnUser = false;
  whiteList = [];
  currentUser: CurrentUserDto;
  uploader: FileUploader = new FileUploader({
    url: 'api/Document/Upload',
    autoUpload: false,
    removeAfterUpload: true,
    maxFileSize: MAX_FILE_SIZE,
    queueLimit: 1,
  });

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  documentAdded = new EventEmitter();
  static open(dialog: MatDialog, document: EditDocumentDto): Promise<any> {
    return dialog
      .open(EditDocumentComponent, {
        width: '1000px',
        data: document,
      })
      .afterClosed()
      .toPromise();
  }
  constructor(
    public dialogRef: MatDialogRef<EditDocumentComponent>,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: EditDocumentDto,
    private itemViewService: ItemViewService,
    public matDialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    public downloader: FileObjectDownloader,
    private userService: UserService
  ) {
    this.userService.currentUser$.subscribe(cu => {
      this.setCurrentUser(cu);
    });

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(tag => (tag ? this.filter(tag) : null))
    );
  }

  async ngOnInit() {
    this.getAcquisitionLifecycles();
    this.getDocumentData();
    this.getAccessLevels();
    this.getApprovedFileTypes();
    this.setCurrentUser(this.userService.cu);
    this.uploader.onCompleteItem = this.onComplete;
    this.uploader.onProgressItem = this.onProgressItem;
    this.uploader.onBeforeUploadItem = this.beforeUploadItem;
  }

  setCurrentUser(cu: CurrentUserDto) {
    if (!cu) {
      return;
    }
    this.currentUser = cu;
  }

  getApprovedFileTypes() {
    this.dataService.settings.getWhitelistTags().subscribe(result => {
      result.forEach(r => {
        this.whiteList.push(r.Extension);
      });
    });
  }

  filter(tag: string) {
    return this.keywordTags.filter(option => option.Name.toLowerCase().includes(tag.toLowerCase()));
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      if (input && input.value !== '') {
        if (input.value.trim() !== '') {
          this.snackBar.open('tag does not exist!', '', {
            duration: 1500,
          });
        }
        input.value = '';
      }
      this.tagCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.tags.findIndex(x => x.Name === event.option.viewValue) > -1) {
      this.snackBar.open('tag has already been added!', '', {
        duration: 1500,
      });
    } else {
      this.tags.push(this.keywordTags.find(t => t.Name === event.option.viewValue));
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  remove(tag: LibraryTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  getAccessLevels() {
    this.dataService.accessLevel.getAccessLevels().subscribe(levels => {
      this.accessLevels = levels.filter(el => el.Enabled === true);
      return this.accessLevels;
    });
  }
  getAcquisitionLifecycles() {
    this.dataService.libraries.getLibraryTags().subscribe(result => {
      this.acquisitions = result.Lifecycles;
      this.roles = result.Roles;
      this.keywordTags = result.Tags;
    });
  }

  getDocumentData() {
    this.document = this.data;
    // set this.tags as document tags
    this.tags = this.document.Tags;
    this.accessLevels = this.document.AccessLevels;
  }

  onAcquisitionChanged(a) {
    const index = this.document.Lifecycles.map(x => x.LifecycleId).indexOf(a.Id);
    if (index > -1) {
      this.document.Lifecycles.splice(index, 1);
    } else {
      a.LifecycleId = a.Id;
      this.document.Lifecycles.push(a);
    }
  }

  isAcquisitionChecked(a) {
    return this.document.Lifecycles.findIndex(x => x.LifecycleId === a.Id) >= 0;
  }

  onRoleChanged(r: DocumentToRole) {
    const index = this.document.Roles.map(x => x.RoleId).indexOf(r.Id);
    if (index > -1) {
      this.document.Roles.splice(index, 1);
    } else {
      r.RoleId = r.Id;
      this.document.Roles.push(r);
    }
  }

  isRoleChecked(r: DocumentToRole) {
    return this.document.Roles.findIndex(x => x.RoleId === r.Id) >= 0;
  }

  onAccessLevelChanged(a) {
    const index = this.document.AccessLevels.map(x => x.AccessLevelId).indexOf(a.Id);
    if (index > -1) {
      this.document.AccessLevels.splice(index, 1);
    } else {
      let al = new DocumentToAccessLevelDto();
      al.AccessLevelId = a.Id;
      this.document.AccessLevels.push(al);
    }
  }

  isAccessLevelChecked(a) {
    if (this.document) {
      return this.document.AccessLevels.findIndex(x => x.AccessLevelId === a.Id) >= 0;
    }
  }

  async previewDocument(item) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: this.document.Id, Location: ItemViewLocation.EditDocument }));
    const fileObject = new FileObjectDto({ Id: this.document.Id, Name: item.DocumentName, IsNavigator: false, DocumentVersionId: item.Id });
    await PreviewDocumentComponent.open(this.matDialog, fileObject);
  }

  async download(item) {
    this.itemViewService.saveItemView(new SaveItemViewRequest({ DocumentId: item.Id, Location: ItemViewLocation.EditDocument }));
    this.downloader.downloadVersion(item.Id, item.DocumentName);
  }

  restoreDocument(version) {
    this.pendingRestoredDocumentVersion = Object.assign(DocumentVersionDto, version);
    this.pendingRestoredDocumentVersion.CreatedDate = null;
    this.pendingRestoredDocumentVersion.CreatedByFirstName = null;
    this.pendingRestoredDocumentVersion.CreatedByLastName = null;
    this.document.Versions.unshift(this.pendingRestoredDocumentVersion);
    console.log(this.document);
  }

  restoreDocumentVersionDataCall() {
    const restoreDocumentVersionRequest = new RestoreDocumentVersionRequest({
      CurrentUserId: this.currentUser.Id,
      DocumentId: this.document.Id,
      DocumentVersionId: this.pendingRestoredDocumentVersion.Id,
    });
    this.dataService.document.restoreDocumentVersion(restoreDocumentVersionRequest).subscribe(result => {});
  }

  save() {
    if (this.uploader.queue.length > 0) {
      this.uploader.uploadAll();
    } else {
      if (this.pendingRestoredDocumentVersion) {
        this.restoreDocumentVersionDataCall();
      }
      const document = new UpdateDocumentObjectRequest(<any>this.document);
      document.Id = this.document.Id;
      const newLifecycles: DocumentToLifecycleDto[] = document.Lifecycles;
      document.Lifecycles = [];
      newLifecycles.forEach(x => {
        x.DocumentId = document.Id;
        if (x.Id === x.LifecycleId) {
          x.Id = null;
        }
        document.Lifecycles.push(x);
      });
      const newRoles: DocumentToRoleDto[] = document.Roles;
      document.Roles = [];
      newRoles.forEach(x => {
        x.DocumentId = document.Id;
        if (x.Id === x.RoleId) {
          x.Id = null;
        }
        document.Roles.push(x);
      });
      const newAccessLevels: DocumentToAccessLevelDto[] = document.AccessLevels;
      document.AccessLevels = [];
      newAccessLevels.forEach(x => {
        x.DocumentId = document.Id;
        if (x.Id === x.AccessLevelId) {
          x.Id = null;
        }
        document.AccessLevels.push(x);
      });

      const newTags: DocumentToTagDto[] = this.tags;
      document.Tags = [];
      newTags.forEach(x => {
        x.DocumentId = document.Id;
        if (!x.TagId) {
          x.TagId = x.Id;
          x.Id = null;
        }
        document.Tags.push(x);
      });
      this.dataService.document.update(document).subscribe(response => {
        this.close();
        this.snackBar.open('The document was updated!', '', {
          duration: 1500,
        });
      });
    }
  }

  showWarnUser() {
    this.warnUser = !this.warnUser;
  }

  updateDeleteDocument() {
    const updateDeleteDocument = new UpdateDocumentObjectRequest();
    updateDeleteDocument.Id = this.document.Id;
    this.dataService.document.updateDeleteDocument(updateDeleteDocument).subscribe(response => {
      this.close();
      // check if document was deleted on modal load
      if (!this.document.Deleted) {
        this.snackBar.open('The document was deleted!', '', {
          duration: 1500,
        });
      } else {
        this.snackBar.open('The document was restored!', '', {
          duration: 1500,
        });
      }
    });
  }

  docAdded(doc) {
    this.inflight = true;
    const replaceDocumentVersionAndDelete = new ReplaceDocumentVersionAndDeleteRequest({
      NewDocumentId: doc.Id,
      OriginalDocumentId: this.document.Id,
      NewTitle: doc.Name,
    });
    const document = new UpdateDocumentObjectRequest((<any>doc).uploaderItem);
    document.Id = this.document.Id;
    document.Description = this.document.Description;
    document.Title = this.document.Title;
    const newLifecycles: DocumentToLifecycleDto[] = this.document.Lifecycles;
    document.Lifecycles = [];
    newLifecycles.forEach(x => {
      x.DocumentId = document.Id;
      if (x.Id === x.LifecycleId) {
        x.Id = null;
      }
      document.Lifecycles.push(x);
    });
    const newRoles: DocumentToRoleDto[] = this.document.Roles;
    document.Roles = [];
    newRoles.forEach(x => {
      x.DocumentId = document.Id;
      if (x.Id === x.RoleId) {
        x.Id = null;
      }
      document.Roles.push(x);
    });
    const newTags: DocumentToTagDto[] = this.tags;
    document.Tags = [];
    newTags.forEach(x => {
      x.DocumentId = document.Id;
      if (!x.TagId) {
        x.TagId = x.Id;
        x.Id = null;
      }
      document.Tags.push(x);
    });
    const newAccessLevels: DocumentToAccessLevelDto[] = document.AccessLevels;
    document.AccessLevels = [];
    newAccessLevels.forEach(x => {
      x.DocumentId = document.Id;
      if (x.Id === x.AccessLevelId) {
        x.Id = null;
      }
      document.AccessLevels.push(x);
    });
    // instead of update, create an "updateAndReplace" method to update the doc version
    this.dataService.document.update(document).subscribe(response => {
      this.close();
      this.snackBar.open('The document was updated successfully.', '', {
        duration: 1500,
      });
      // create a delete method to delete the duplicate document record and keep the version
      this.dataService.document.replaceDocumentVersionAndDelete(replaceDocumentVersionAndDelete).subscribe();
    });
  }

  removeDocument(file: FileItem) {
    this.uploader.queue = this.uploader.queue.filter(fileItem => fileItem !== file);
  }

  updateErrorMessage(message: string) {
    this.fileUploadErrorMessage = message;
  }

  shouldISeeRestore(version) {
    if (
      version.Id &&
      this.document.CurrentVersion !== version.Id &&
      version.CreatedDate &&
      version.DocumentStatusTypeCode === DocumentStatusType.Approved
    ) {
      return true;
    }
    return false;
  }

  onProgressItem = () => {
    this.changeDetector.detectChanges();
  };

  onComplete = item => {
    if (item && item._xhr && item._xhr.response !== '') {
      const doc = JSON.parse(item._xhr.response)[0];
      if (doc) {
        doc.uploaderItem = item;
        this.docAdded(doc);
      } else {
        this.showErrorMessage(item);
      }
    } else {
      this.showErrorMessage(item);
    }
  };

  showErrorMessage(item) {
    let errmsg = 'unable to upload a document';
    if (item && item._file && item.file.name) {
      errmsg = 'unable to upload document: ' + item.file.name;
    }
    if (item._xhr.status === 500) {
      const error = JSON.parse(item._xhr.response);
      if (error.ExceptionMessage) {
        errmsg += ' ' + error.ExceptionMessage;
      }
    }
    const sb = this.snackBar.openFromComponent(SnackBarErrorComponent, {
      data: errmsg,
    });
    const dismiss = sb.instance.dismiss.subscribe(x => {
      sb.dismiss();
    });
  }

  addToDocumentVersionArray() {
    const newDocumentVersion = new DocumentVersionDto({
      DocumentName: this.uploader.queue[0].file.name,
      DocumentId: this.document.Id,
    });
    this.document.Versions.unshift(newDocumentVersion);
  }

  beforeUploadItem = () => {};

  close(result: boolean = true): void {
    this.dialogRef.close(result);
  }
}
