import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-homepage',
  templateUrl: './edit-homepage.component.html',
  styleUrls: ['./edit-homepage.component.scss']
})
export class EditHomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
