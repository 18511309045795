import { LifecycleGroupToLifecycle } from './LifecycleGroupToLifecycle';

export class SaveLifeCycleGroupRequest {
	public Id: number ;
	public LifeCycles: LifecycleGroupToLifecycle[]  = [];
	public Name: string ;
	public Ordinal: number ;
	public SvgFileName: string ;
	public SvgFilePath: string ;
	public SvgForLifecycle: boolean ;

	public constructor(
		fields?: Partial<SaveLifeCycleGroupRequest>) {

		if (fields) {


			if (fields.LifeCycles) { fields.LifeCycles = fields.LifeCycles.map(x => new LifecycleGroupToLifecycle(x)); }
			Object.assign(this, fields);
		}
	}
}
